.dinein-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.dinein-popup {
  position: relative; /* Make popup the positioning context for the close icon */
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 80%;
  max-width: 800px;
  height: 45%;
  display: flex;
  flex-direction: column;
}

.dinein-popup-header {
  margin: 0 auto 20px auto;
  font-size: 22px;
  font-family: Fraunces;
  font-weight: 700;
  margin-bottom: 30px;
  color: #094344;
  width: 40%;
  text-align: center;
}

.dinein-popup-options {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 20px;
}

.dinein-popup-button {
  padding: 10px 20px;
  background: #fff;
  color: #000;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  font-size: 18px;
  font-family: Fraunces;
  font-weight: 700;
  border: 1px solid #ceee36;
  margin-top: 15px;
}

.dinein-popup-button:hover {
  background: #ceee36;
}

.dinein-popup-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  color: #d30c0c; /* Adjust color as needed */
}
