.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f9fde5;
    min-height: 90vh;
    position: relative;
}

.form-header {
    position: absolute;
    top: 10px;
    right: 10px;
}

.form-header-h3{
    font-family: Fraunces;
    color: #333;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 20px;
}
.form {
    background-color: #fff;
    padding: 20px;
    margin-top: 2%;
    width: 50%;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.form h3 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
    font-family: Fraunces;
}

/* .form-group {
    margin-bottom: 15px;
} */

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 700;
    font-family: Fraunces;
    color: #333;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}
.social-links {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  .social-link {
    flex: 1;
  }
  
  .social-link input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
.form-group input:focus,
.form-group textarea:focus {
    outline: none;
    border-color: #66afe9;
}
.form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
.form-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.form button[type="submit"] {
    background-color: #ceee36;
    color: #000;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    font-family: 'Fraunces', sans-serif;
    font-weight: 700;
}

.form button[type="submit"]:hover {
    background-color: #b0d12e;
}

.close-button-secondary {
    background-color: transparent;
    color: #000;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    border: 1px solid #f44336;
    cursor: pointer;
    font-size: 14px;
    font-family: 'Fraunces';
    font-weight: 700;
}

.close-button-secondary:hover {
    background-color: #f44336;
}
.form-group input,
.form-group textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    margin-bottom: 10px; /* Add spacing for the new inputs */
}

.form-group label {
    margin-bottom: 5px;
    font-weight: 700;
    color: #333;
}

.form-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}