.error-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  min-height: 91vh;
  padding: 0 50px;
  background: linear-gradient(#f9fde500, #f9fde5, #f9fde5);
  background-color: #f9f9f9;
  font-family: 'Poppins', sans-serif;
}

.error-content {
  flex: 1 1 50%;
  max-width: 50%;
  padding: 20px;
}

.error-content h1 {
  font-size: 3em;
  font-family: Fraunces;
  color: #094344;
  margin-bottom: 20px;
}

.error-content p {
  font-size: 1.2em;
  font-family: inter;
  color: #666;
  margin-bottom: 30px;
}

.error-content button {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  background-color: #ceee36;
  color: #000;
  border: none;
  border-radius: 20px;
  font-family: Fraunces;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.error-content button:hover {
  background-color: #b3cc2c;
}

.error-image {
  flex: 1 1 50%;
  display: flex;
  justify-content: center;
  padding: 20px;
}

.error-image img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 1024px) {
  .error-page {
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }

  .error-content,
  .error-image {
    max-width: 100%;
    flex: 1 1 100%;
  }

  .error-content h1 {
    font-size: 2.5em;
  }

  .error-content p {
    font-size: 1em;
  }

  .error-content button {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .error-content h1 {
    font-size: 2em;
  }

  .error-content p {
    font-size: 0.9em;
  }

  .error-content button {
    font-size: 12px;
  }
}