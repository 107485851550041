.blog-desktop {
  background-color: #f0f0f0;
  min-height: 100vh;
  font-family: 'Inter', sans-serif;
}

.blog-content {
  max-width: 100%;
  margin: 0 auto;
  padding: 40px 20px;
  background: linear-gradient(
    #f9fde5,
    #f9fde5,
    #f9fde500,
    #f9fde5,
    #f9fde5,
    #f9fde500
  );
}

.blog-section {
  margin-bottom: 40px;
}

.blog-nav {
  text-align: center;
  margin-bottom: 20px;
}

.blog-nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
}

.blog-nav li {
  margin: 0 10px;
  cursor: pointer;
  font-size: 1.2rem;
  color: #094344;
  font-family: 'Fraunces', serif;
  text-transform: uppercase;
  padding-bottom: 5px;
  border-bottom: 2px solid transparent;
  transition: color 0.3s ease, border-bottom 0.3s ease;
}

.blog-nav li:hover {
  color: #ceee36;
}

.blog-nav li.selected {
  border-bottom-color: #ceee36;
  font-weight: bold;
}

.blog-posts {
  display: flex;
  justify-content: center;
  width: 95%;
  gap: 23px;
  flex-wrap: wrap;
}

.blog-post {
  width: 30%;
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 15px;
  text-align: center; 
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.blog-post:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}
.blog-posttrend {
  /* width: 30%; */
  border-radius: 20px;
  height: auto;
  margin-bottom: 20px;
  padding: 5px;
  text-align: center; 
  border:2px solid #ceee36;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.blog-post-img{
  max-width: 380px;
  max-height:380px ;
  border-radius: 8px;
}
.blog-posttrend:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}
.blog-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog-image {
  width: 200px; 
  height: 200px; 
  object-fit: cover; 
  border-radius: 8px;
  margin-bottom: 10px;
}

.blog-text-content {
  text-align: center;
}

.blog-post-title {
  font-size: 1.5rem;
  margin-bottom: 8px;
  color: #11989a;
  font-family: 'Fraunces', serif;
}

.blog-post-content {
  color: #333333;
  font-family: 'Inter', sans-serif;
}
.blog-post-img{
  max-width: 380px;
  max-height:380px ;
}
@media (max-width: 768px) {
  .blog-post {
    width: 100%;
  }
}
