.mob-order-page {
  min-height: 100vh;
  background: linear-gradient(#f9fde500, #f9fde5, #f9fde5, #f9fde500);
}

.mob-order-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.mob-back-button,
.mob-logo {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.mob-order-title {
  font-family: Fraunces;
  color: #094344;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 0;
  text-transform: uppercase;
  opacity: 1;
}

.mob-order-content {
  flex: 1;
  padding: 60px 10px 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.mob-order-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.mob-order-past-order {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
}

.mob-order-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}

.mob-order-image {
  width: 20%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 15px;
  flex: 0 0 auto;
}

.mob-order-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  flex: 1;
}

.mob-order-name {
  font-size: 16px;
  font-family: Fraunces;
  color: #094344;
  font-weight: bold;
}

.mob-order-id,
.mob-order-date {
  font-size: 14px;
  color: #777;
  font-family: inter;
}

.mob-order-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.mob-reorder-button {
  background-color: #ceee36;
  color: #000;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-family: Fraunces;
  font-weight: 700;
  font-size: 14px;
}

.mob-reorder-button:hover {
  background-color: #b0d12e;
}

.mob-order-details-buttonandimg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.mob-order-price {
  font-size: 16px;
  font-weight: bold;
  font-family: inter;
}

.popup-menu {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 75%;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  padding: 20px;
  z-index: 2000;
}

.pop-menu-navbar-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.popup-menu.show {
  display: block;
}

.popup-menu ul {
  list-style: none;
  padding: 15px 0;
  margin: 0;
}

.pop-menu-navbar-img {
  width: 12%;
  height: 10%;
}

.popup-menu ul li {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-size: 18px;
  cursor: pointer;
  width: 75%;
}

.close-popup {
  display: block;
  margin-top: 20px;
  padding: 10px;
  background-color: #ceee36;
  border: none;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
}

.close-popup:hover {
  background-color: #b0d12e;
}
