.category-creation-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
}

.category-creation-navbar {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  color: #000000;
  padding: 20px;
  font-size: 24px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  justify-content: space-around;
}

.sidemainlogo {
  width: 50px;
}

.category-back-button {
  width: 30px;
}

.category-creation-title {
  font-weight: bold;
  flex: 1;
  text-align: center;
}

.category-creation-content {
  display: flex;
  margin-top: 80px;
  background: linear-gradient(#f9fde500, #f9fde5, #f9fde5);
  height: calc(100vh - 80px);
  padding: 20px;
}

.sticky-sidebar {
  background-color: #f9fde5;
  position: sticky;
  top: 80px;
  width: 200px;
  height: calc(100vh - 80px);
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  font-size: 18px;
}

.sidebar-item {
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  transition: color 0.3s, background-color 0.3s;
}

.sidebar-item.selected {
  color: #000;
  background-color: #ceee36;
  border-radius: 10px;
}

.sidebar-item:hover {
  color: #ceee36;
}

.sidebar-img {
  width: 30px;
}

.category-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-creation-form {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 40px;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.category-creation-field {
  display: flex;
  flex-direction: column;
}

.category-creation-field label {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
}

.category-creation-field input[type="text"],
.category-creation-field input[type="file"] {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 16px;
}

.category-creation-submit-button {
  background-color: #4caf50;
  color: #ffffff;
  border: none;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.category-creation-submit-button:hover {
  background-color: #45a049;
}

@media (max-width: 768px) {
  .category-creation-navbar {
    padding: 10px;
    font-size: 20px;
  }

  .category-creation-title {
    font-size: 20px;
  }

  .category-creation-form {
    padding: 20px;
    max-width: 300px;
  }

  .category-creation-submit-button {
    padding: 10px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .category-creation-navbar {
    padding: 5px;
    font-size: 16px;
  }

  .category-creation-title {
    font-size: 18px;
  }

  .category-creation-form {
    padding: 15px;
    max-width: 100%;
  }

  .category-creation-submit-button {
    padding: 8px;
    font-size: 12px;
  }
}
