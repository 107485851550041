.mob-personal-sign-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mob-personal-sign-head {
  background: #11989a;
  padding: 20px;
  border-radius: 0 0 50px 50px;
  position: relative;
  width: 100%;
}

.mob-personal-sign-steps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
  position: relative;
  padding: 20px;
}

.mob-personal-sign-step {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #11989a;
  border-radius: 50%;
  font-size: 18px;
  font-weight: bold;
  background-color: #11989a;
  color: #fff;
}

.mob-personal-sign-line {
  position: absolute;
  height: 2px;
  background-color: #11989a;
  z-index: -1;
}

.mob-personal-sign-line-1 {
  width: 46%;
  transform: translateY(-50%);
}

.mob-personal-sign-animated-line {
  position: absolute;
  height: 2px;
  background-color: #11989a;
  z-index: -1;
  left: 48%;
  transform: translateY(-50%);
  animation: expandLine 7s linear forwards;
}

@keyframes expandLine {
  0% {
    width: 0%;
  }
  100% {
    width: 47%;
  }
}

.mob-personal-sign-content {
  padding: 20px;
  border-radius: 10px;
}
.mob-otp-sign-main-otp-section-head{
  font-size: 18px;
  font-weight: bold;
  font-family: inter;
  color: #094344;
  margin-bottom: 10px;
}
.mob-otp-sign-main-otp-section-p{
  font-size: 15px;
  font-weight: 400;
  font-family: inter;
}
.mob-otp-sign-main-otp-head{
  font-weight: bold;
  font-family: Fraunces;
  color: #094344;
}
.mob-otp-sign-main-otp-resend{
  font-family: inter;
}
.mob-personal-sign-fields-container {
  display: flex;
  flex-wrap: wrap;
  gap: 4%;
  margin-top: 5%;
  width: 100%;
}

.mob-personal-sign-field {
  flex: 0 0 48%;
  margin-bottom: 20px;
}

.mob-personal-sign-field p {
  margin-bottom: 5px;
  font-weight: bold;
  font-family: Fraunces;
  color: #094344;
}

.mob-personal-sign-field input {
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #eee;
}

.mob-personal-sign-field input:focus {
  border-bottom: 1px solid #11989a;
  outline: none;
}

.mob-personal-sign-fields-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.mob-personal-sign-content-head{
  font-weight: bold;
  font-family: Fraunces;
  color: #094344;
}
.mob-finish-sign-up-button {
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bold;
  color: rgb(0, 0, 0);
  background-color: #ceee36;
  font-weight: bold;
  font-family: Fraunces;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.mob-finish-sign-up-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.mob-finish-sign-up-button:hover:enabled {
  background-color: #bada29;
}

@media (max-width: 768px) {
  .mob-personal-sign-steps {
    width: 82%;
  }

  .mob-personal-sign-fields-container {
    flex-direction: column;
    align-items: center;
  }

  .mob-personal-sign-field {
    width: 100%;
  }
}
