/* body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: Arial, sans-serif;
} */

.mob-login-no-scroll {
  overflow: hidden;
}

.mob-login-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  height: 50px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.mob-login-back-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.mob-login-navbar-title {
  flex-grow: 1;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  font-family: Fraunces;
  color: #094344;
  margin: 0;
}

.mob-login-navbar-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.mob-login-page-container {
  display: flex;
  flex-direction: column;
  height: 85vh;
  background-size: cover;
  background-image: url("../../../images/Desktop-icons/Page 7.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
  position: relative;
}

.mob-login-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #eee;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.mob-login-user-icon {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.mob-login-welcome-text {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: Fraunces;
  color: #094344;
}

.mob-login-signup-button {
  padding: 10px 20px;
  font-size: 18px;
  font-family: Fraunces;

  font-weight: 700;
  color: rgb(0, 0, 0);
  background-color: #ceee36;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.mob-login-signup-button:hover {
  background-color: #aad530;
}

.mob-login-help-center-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.mob-login-help-center-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.mob-login-help-center-link {
  font-size: 18px;
  color: #000;
  text-decoration: none;
  font-family: inter;
}

.mob-login-help-center-link:hover {
  text-decoration: underline;
}

/* Popup styles */
.mob-login-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.mob-login-content-button-sec{
  display: flex;
  width: 80%;
  justify-content: space-between;
}
.mob-login-popup-box {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 80%;
}

.mob-login-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-family: Fraunces;
  background-color: #ccc;
  color: #000;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.mob-login-close-button:hover {
  background-color: #aaa;
}
.mob-login-notsign-field span{
  font-family: inter;
  color:#094344 ;
  cursor: pointer;
  text-decoration: underline;
}
.mob-login-header h1 {
  margin: 0 0 20px;
  font-family: Fraunces;
  color: #094344;
  font-size: 24px;
  text-align: center;
}

.mob-login-form {
  display: flex;
  flex-direction: column;
}

.mob-login-field {
  margin-bottom: 15px;
}

.mob-login-field label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  font-family: Fraunces;
  color: #094344;
}

.mob-login-field input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.mob-login-actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mob-login-button {
  padding: 10px 20px;
  font-size: 16px;
  font-family: Fraunces;
font-weight: 700;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  background-color: #ceee36;
  color: #000;
}

.mob-login-button:hover {
  background-color: #aad530;
}

.mob-login-notsign-field {
  text-align: center;
  margin-bottom: 15px;
}

.mob-login-notsign-field p {
  margin: 0;
  font-family: inter;
}



@media (max-width: 768px) {
  .mob-login-content {
    width: 90%;
    padding: 15px;
  }

  .mob-login-user-icon {
    width: 80px;
    height: 80px;
  }

  .mob-login-welcome-text {
    font-size: 20px;
  }

  .mob-login-signup-button {
    font-size: 16px;
  }

  .mob-login-popup-box {
    width: 90%;
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .mob-login-user-icon {
    width: 60px;
    height: 60px;
  }

  .mob-login-welcome-text {
    font-size: 18px;
  }

  .mob-login-signup-button {
    font-size: 14px;
  }

  .mob-login-popup-box {
    width: 95%;
    padding: 10px;
  }
  .error{
    color: red;
  }
}
