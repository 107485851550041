.cart-full-width-container {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.sidemainlogo {
  position: fixed;
  /* left: 23px; */
  /* top: 7%; */
  z-index: 1000;
  width: 7%;
  height: 11%;
}

.cart-navbar {
  display: flex;
  align-items: center;
  gap: 30px;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  padding: 10px 15px 10px 30px;
  font-size: 20px;
  box-shadow: 0 3px 0px rgba(0, 0, 0, 0.08235294117647059);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.cart-back-button {
  width: 24px;
  height: 24px;
  margin-left: 14%;
  cursor: pointer;
}

.cart-item-container {
  background: linear-gradient(#f9fde500, #f9fde500, #f9fde5);
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  overflow-y: auto;
  margin-top: 60px;
}

.cart-secondbox,
.cart-ordertypebox {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  margin: 15px 0;
  width: 90%;
  max-width: 600px;
  border: 2px solid #eee;
}

.cart-thirdbox {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  margin: 15px 0;
  width: 90%;
  max-width: 600px;
  border: 2px solid #eee;
}

.cart-page-title {
  font-family: Fraunces;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 0;
  color: #094344;
  text-transform: uppercase;
  opacity: 1;
}

.cart-product-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 10px;
}

.cart-billdetatilstext {
  font-size: 24px;
  margin-top: 20px;
  padding: 10px;
  text-align: left;
  font-family: Fraunces;
  font-weight: bold;
  letter-spacing: 0;
  color: #094344;
}

.cart-topaytext {
  font-size: 18px;
  margin-top: 20px;
  padding: 10px;
  text-align: left;
  font-family: Fraunces;
  font-weight: bold;
  letter-spacing: 0;
  color: #094344;
}

.item-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  margin-top: 20px;
  padding: 8px;
  text-align: left;
}

.shop-closed-message {
  margin-top: 10%;
}

.cart-thirdbox-item-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  margin-top: 20px;
  font-weight: 500;
  font-family: inter;
  padding: 5px 10px;
  text-align: left;
}

.cart-product-details-name {
  font-size: 16px;
  font-weight: 500;
  font-family: inter;
  color: #094344;

}

.cart-whatsapp-text {
  font-size: 16px;
  font-weight: 500;
  font-family: inter;
}

.cart-item-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  margin-top: 20px;
  padding: 10px;
  text-align: left;
}

.cart-addaddressandpaybutton {
  display: inline-block;
  padding: 12px 20px;
  margin: 10px 0;
  font-size: 19px;
  font-weight: 700;
  font-family: Fraunces;
  color: #000;
  background-color: #ceee36;
  border: none;
  width: 100%;
  margin-top: 10%;
  border-radius: 20px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

/* .cart-addaddressandpaybutton:hover {
  background-color: #0056b3;
} */

.cart-grey-line {
  border: none;
  height: 1px;
  background-color: #eee;
  margin-top: 20px;
  width: 100%;
}

.cart-item-description {
  margin-top: 5px;
  font-size: 16px;
  color: #666;
  font-family: inter;
}

.cart-item-price {
  font-size: 18px;
  color: #333;
  font-weight: 500;
  font-family: inter;
  margin-top: 10px;
}

.cart-change-text {
  color: #ceee36;
  font-weight: 600;
  cursor: pointer;
  font-size: 14px;
}

.cart-whatsappicon {
  width: 5%;
}

.cart-items-in-cart {
  font-size: 18px;
  font-weight: 800;
  margin-top: 20px;
  font-family: Fraunces;
  letter-spacing: 0;
  color: #094344;
  text-transform: uppercase;
}

.cart-allitemsdiv {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.cart-quantity-control {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.cart-quantity-button {
  background-color: #ceee36;
  color: #000;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
  margin-right: 5px;
}

.cart-order-button {
  background-color: #ffffff;
  color: #000;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  border-radius: 51px;
  font-weight: 700;
  font-family: Fraunces;
  border: 2px solid #eeee;
  font-size: 16px;
  cursor: pointer;
}

.cart-order-button.active {
  background-color: #ceee36;
  color: #000;
}

/* .cart-quantity-button:hover {
  background-color: #0056b3;
} */

.cart-quantity-display {
  font-size: 16px;
  margin: 0 10px;
}

.cart-ordertype-title {
  font-weight: bold;
  font-size: 18px;
  text-align: left;
  font-family: Fraunces;
  letter-spacing: 0;
  color: #094344;
}

.cart-ordertype-options {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.cart-ordertype-options label {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.cart-related-products {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  margin: 15px 0;
  width: 100%;
  max-width: 600px;
  border: 2px solid #eee;
}

.cart-related-products-title {
  font-size: 18px;
  margin-bottom: 10px;
  font-family: Fraunces;
  font-weight: bold;
  letter-spacing: 0;
  color: #094344;
}

.cart-related-products-list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.cart-related-product-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #eee;
}

.cart-related-product-add-button {
  background-color: #ceee36;
  color: #000;
  border: none;
  border-radius: 15px;
  width: 75px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  font-family: Fraunces;
}

.cart-related-product-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 10px;
}

.cart-ordertype-and-related-container {
  /* display: flex;
  justify-content: space-between; */
  justify-content: space-between;
  width: 90%;
  max-width: 600px;
  margin: 15px 0;
}

.cart-ordertypebox,
.cart-related-products {
  flex: 1;
  margin: 0 10px;
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  border: 2px solid #eee;
}

.cart-related-product-details p {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  font-family: inter;
  color: #094344;
}
.cart-related-product-details{
  display: flex;
  align-items: center;
  gap: 15px;
}
.cart-related-product-price {
  font-size: 18px;
  color: #333;
  font-weight: 500;
  font-family: inter;
  margin-top: 10px;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 400px;
  text-align: center;
}

.popup-textarea {
  width: 100%;
  height: 100px;
  margin: 10px 0;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.popup-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.popup-button {
  background-color: #ceee36;
  color: #000;
  border: none;
  border-radius: 24px;
  font-weight: 700;
  font-family: Fraunces;
  padding: 10px 15px;
  cursor: pointer;
  flex: 1;
}

.popup-button.skip-button {
  background-color: #ccc;
}

.popup-button.add-button {
  background-color: #ceee36;
}

.cart-preparation-instructions {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

}
.cart-delete-button {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
  border-radius: 15px;
  width: 95px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  font-family: Fraunces;
}

.cart-delete-button:hover {
  background-color: #11989a;
}

.cart-preparation-button {
  background-color: #fff;
  color: #094344;
  font-family: inter;
  font-weight: 400;
  border: none;
  border-bottom: 1px solid #eeee;
  padding: 10px 15px;
  cursor: pointer;
}

.popup-pre-head {
  font-weight: bold;
  font-size: 18px;
  color: #094344;
  font-family: Fraunces;
}

.shop-closed-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.shop-closed-container {
  background: white;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  height: 70%;
}

.shop-closed-title {
  font-size: 24px;
  font-weight: bold;
  color: #094344;
  font-family: Fraunces;
}

.shop-closed-message {
  font-size: 18px;
  margin-top: 10px;
  color: #094344;
  font-family: Fraunces;
}

.shop-container {
  width: 300px;
  height: 280px;
  position: absolute;
  top: calc(50% - 140px);
  left: calc(50% - 160px);
}

.shop-closed-header {
  width: 100%;
  height: 80px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ddcfcc;
  border-radius: 10px;
}

.shop-closed-header__buttons {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 25px;
  background-color: #282323;
  border-radius: 50%;
}

.shop-closed-header__buttons::after {
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  bottom: -8px;
  left: calc(50% - 4px);
  background-color: #615e5e;
}

.shop-closed-header__button-one {
  left: 15px;
}

.shop-closed-header__button-two {
  left: 50px;
}

.shop-closed-header__display {
  width: 50px;
  height: 50px;
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  border-radius: 50%;
  background-color: #9acfc5;
  border: 5px solid #43beae;
  box-sizing: border-box;
}

.shop-closed-header__details {
  width: 8px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #9b9091;
  box-shadow: -12px 0 0 #9b9091, -24px 0 0 #9b9091;
}

.shop-closed-medium {
  width: 90%;
  height: 160px;
  position: absolute;
  top: 80px;
  left: calc(50% - 45%);
  background-color: #bcb0af;
}

.shop-closed-medium:before {
  content: "";
  width: 90%;
  height: 100px;
  background-color: #776f6e;
  position: absolute;
  bottom: 0;
  left: calc(50% - 45%);
  border-radius: 20px 20px 0 0;
}

.shop-closed-medium__exit {
  width: 60px;
  height: 20px;
  position: absolute;
  top: 0;
  left: calc(50% - 30px);
  background-color: #231f20;
}

.shop-closed-medium__exit::before {
  content: "";
  width: 50px;
  height: 20px;
  border-radius: 0 0 50% 50%;
  position: absolute;
  bottom: -20px;
  left: calc(50% - 25px);
  background-color: #231f20;
}

.shop-closed-medium__exit::after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: -30px;
  left: calc(50% - 5px);
  background-color: #231f20;
}

.shop-closed-medium__arm {
  width: 70px;
  height: 20px;
  position: absolute;
  top: 15px;
  right: 25px;
  background-color: #231f20;
}

.shop-closed-medium__arm::before {
  content: "";
  width: 15px;
  height: 5px;
  position: absolute;
  top: 7px;
  left: -15px;
  background-color: #9e9495;
}

.shop-closed-medium__cup {
  width: 80px;
  height: 47px;
  position: absolute;
  bottom: 0;
  left: calc(50% - 40px);
  background-color: #FFF;
  border-radius: 0 0 70px 70px / 0 0 110px 110px;
}

.shop-closed-medium__cup::after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: 6px;
  right: -13px;
  border: 5px solid #FFF;
  border-radius: 50%;
}

@keyframes liquid {
  0% {
    height: 0px;
    opacity: 1;
  }

  5% {
    height: 0px;
    opacity: 1;
  }

  20% {
    height: 62px;
    opacity: 1;
  }

  95% {
    height: 62px;
    opacity: 1;
  }

  100% {
    height: 62px;
    opacity: 0;
  }
}

.shop-closed-medium__liquid {
  width: 6px;
  height: 63px;
  opacity: 0;
  position: absolute;
  top: 50px;
  left: calc(50% - 3px);
  background-color: #74372b;
  animation: liquid 4s 4s linear infinite;
}

.shop-closed-medium__smoke {
  width: 8px;
  height: 20px;
  position: absolute;
  border-radius: 5px;
  background-color: #b3aeae;
}

@keyframes smokeOne {
  0% {
    bottom: 20px;
    opacity: 0;
  }

  40% {
    bottom: 50px;
    opacity: .5;
  }

  80% {
    bottom: 80px;
    opacity: .3;
  }

  100% {
    bottom: 80px;
    opacity: 0;
  }
}

@keyframes smokeTwo {
  0% {
    bottom: 40px;
    opacity: 0;
  }

  40% {
    bottom: 70px;
    opacity: .5;
  }

  80% {
    bottom: 80px;
    opacity: .3;
  }

  100% {
    bottom: 80px;
    opacity: 0;
  }
}

.shop-closed-medium__smoke-one {
  opacity: 0;
  bottom: 50px;
  left: 102px;
  animation: smokeOne 3s 4s linear infinite;
}

.shop-closed-medium__smoke-two {
  opacity: 0;
  bottom: 70px;
  left: 118px;
  animation: smokeTwo 3s 5s linear infinite;
}

.shop-closed-medium__smoke-three {
  opacity: 0;
  bottom: 65px;
  right: 118px;
  animation: smokeTwo 3s 6s linear infinite;
}

.shop-closed-medium__smoke-for {
  opacity: 0;
  bottom: 50px;
  right: 102px;
  animation: smokeOne 3s 5s linear infinite;
}

.shop-closed-footer {
  width: 95%;
  height: 15px;
  position: absolute;
  bottom: 25px;
  left: calc(50% - 47.5%);
  background-color: #41bdad;
  border-radius: 10px;
}

.shop-closed-footer::after {
  content: "";
  width: 106%;
  height: 26px;
  position: absolute;
  bottom: -25px;
  left: -8px;
  background-color: #000;
}

@media (min-width: 768px) {
  .cart-item-container {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
  }

  .cart-ordertypebox {
    width: 100%;
    margin: 15px;
  }

  .cart-secondbox {
    width: 90%;
    margin: 15px;
  }

  .cart-thirdbox {
    width: 28%;
    margin: 15px;
  }
}

.cart-go-back-button {
  background-color: #ceee36;
  color: #000;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 4px;
  font-weight: 700;
  font-family: Fraunces;
  position: relative;
  top: 75%;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}