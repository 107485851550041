.terms-main-section {
    width: 100%;
    background: linear-gradient(#f9fde500, #f9fde5, #f9fde500);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
  }
  
  .terms-content {
    text-align: left;
    width: 80%;
    max-width: 800px;
  }
  
  .terms-heading {
    font-family: "Fraunces", serif;
    font-size: 2.5rem;
    color: #11989a;
    margin-bottom: 30px;
  }
  
  .terms-subheading {
    font-family: "Fraunces", serif;
    font-size: 1.2rem;
    color: #11989a;
    margin-bottom: 20px;
  }
  
  .terms-text {
    font-family: "Inter", sans-serif;
    font-size: 1.2rem;
    line-height: 1.6;
    color: #000;
    margin-bottom: 20px;
    
  }

  .terms-toggle-button {
  background-color: transparent;
  border: none;
  color: #11989a;
  cursor: pointer;
  font-size: 1rem;
  font-family: "Fraunces", serif;
  margin-top: 5px;
}

.terms-toggle-button:hover {
  text-decoration: underline;
}
.terms-toggle-button-inline {
  background-color: transparent;
  border: none;
  color: #11989a;
  cursor: pointer;
  font-size: 1rem;
  font-family: "Fraunces", serif;
  margin-left: 5px;
    width: 100px; /* Set a specific width */
  text-align: center;
}

.terms-toggle-button-inline:hover {
  text-decoration: underline;
}
.terms-image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.single-image {
  justify-content: center;
}

.double-image {
  justify-content: space-around;
}

.terms-image-round {
  max-width: 500px;
  max-height: 300px;
  object-fit: cover;
  border-radius: 20px;
}

.terms-image-side {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}



  @media (max-width: 768px) {
    .terms-main-section {
      padding: 30px 0;
    }
    .terms-image-round,
    .terms-image-side {
      width: 150px;
      height: 150px;
    }
    .terms-heading {
      font-size: 2rem;
    }
  
    .terms-subheading {
      font-size: 1.5rem;
    }
  
    .terms-text {
      font-size: 1rem;
    }
  }
  