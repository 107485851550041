.emptycart-full-width-container {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.emptycart-sidemainlogo {
  position: fixed;
  z-index: 1000;
  width: 7%;
  height: 11%;
}

.emptycart-navbar {
  display: flex;
  align-items: center;
  gap: 30px;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  padding: 10px 15px 10px 30px;
  font-size: 20px;
  box-shadow: 0 3px 0px rgba(0, 0, 0, 0.08235294117647059);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.emptycart-back-button {
  width: 24px;
  height: 24px;
  margin-left: 14%;
  cursor: pointer;
}

.emptycart-page-content {
  display: flex;
  flex: 1;
  margin-top: 60px;
  width: 98%;
}

.emptycart-img {
  width: 25%;
  margin-bottom: 25px;
}

.emptycart-sticky-navbar {
  background-color: #f9fde5;
  position: sticky;
  top: 0;
  left: 0;
  width: 200px;
  height: 100%;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 18px;
  display: flex;
  flex-direction: column;
}

.emptycart-navbar-item {
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  font-family: Fraunces;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  color: #094344;
  text-transform: uppercase;
  justify-content: space-between;
  cursor: pointer;
  transition: color 0.3s, border-bottom 0.3s;
}

.emptycart-navbar-item.selected {
  color: #000;
  border-radius: 19px;
  background-color: #ceee36;
}

.emptycart-navbar-item:hover {
  color: #ceee36;
}

.emptycart-navbar-img {
  width: 15%;
  height: 100%;
}

.emptycart-content {
  background: linear-gradient(#f9fde500, #f9fde500, #f9fde5);
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: auto;
}

.emptycart-content-inner {
  text-align: center;
  padding: 50px;
}

.emptycart-title {
  font-size: 24px;
  font-weight: bold;
  color: #094344;
  font-family: Fraunces;
}

.emptycart-description {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 20px;
  color: #094344;
  font-family: inter;
}

.emptycart-button {
  padding: 10px 20px;
  background-color: #ceee36;
  color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  border-radius: 24px;
  font-size: 16px;
  font-family: Fraunces;
  font-weight: 700;
}

.emptycart-button:hover {
  background-color: #b0d12e;
}
