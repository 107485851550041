.otp-sign-main-head {
  background: #11989a 0% 0% no-repeat padding-box;
  width: 100%;
  padding: 10px;
  height: 14vh;
  border-radius: 0 0 65px 65px;
  position: relative;
  background-image: url("../../images/banner-line.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.otp-sign-main-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
}

.otp-sign-main-steps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
  position: relative;
}

.otp-sign-main-step {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 50%;
  font-size: 18px;
  font-weight: bold;
}

.otp-sign-main-step-1,
.otp-sign-main-step-2 {
  background-color: #11989a;
  color: #fff;
}

.otp-sign-main-line {
  position: absolute;
  width: 52%;
  height: 2px;
  background-color: #11989a;
  z-index: -1;
}

.otp-sign-main-ice-van {
  width: 25px;
  height: auto;
  position: absolute;
  left: 54%;
  transform: translateX(-50%);
}

.otp-sign-main-otp-section {
  text-align: left;
  padding: 0 9%;
  height: 15vh;
}

.otp-sign-main-otp-head {
  padding: 15px;
  font-family: Fraunces;
  font-weight: 700;
  color: #094344;
}

.otp-sign-main-otp-resend {
  padding: 20px;
}

.otp-sign-main-second-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 25px;
  max-width: 75%;
}

.otp-sign-main-otp-section p {
  margin: 10px 0;
}

.otp-sign-main-otp-inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.otp-sign-main-otp-header {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.otp-sign-main-otp-columns {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.otp-input {
  width: 40px;
  height: 60px;
  text-align: center;
  font-size: 1.2em;
  border: 1px solid #11989a;
  border-radius: 24px;
}

.otp-input:focus {
  outline: none;
  border-color: #11989a;
}

.otp-submit-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1em;
  background-color: #ceee36;
  color: #000;
  border: none;
  font-family: Fraunces;
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.otp-sign-main-otp-section-head{
  font-family: Fraunces;
margin-top: 20px;
font-size: 20px;
color: #094344;
}
.otp-sign-main-otp-section-p{
  font-family: inter;
font-size: 14px;
font-weight: 400;
margin-top: 10px;
}
.otp-submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.otp-sign-main-otp-resend-p{
  font-family: inter;
  font-weight: 400;
}

@media (max-width: 768px) {
  .otp-sign-main-steps {
    width: 80%;
  }
}
