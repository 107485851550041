.mob-signup-main-head {
  background: #11989a;
  padding: 20px;
  border-radius: 0 0 50px 50px;
  position: relative;
}

.mob-signup-main-step.mob-signup-main-step-1 {
  background-color: #11989a;
  border: none;
}

.mob-signup-main-step.mob-signup-main-step-2,
.mob-signup-main-step.mob-signup-main-step-3 {
  background-color: white;
  border: 2px solid #ccc;
  color: #000;
}

.mob-signup-main-head h1 {
  color: #fff;
  text-align: center;
  font-size: 24px;
  margin-bottom: 10px;
}

.mob-signup-main-body {
  padding: 20px;
}

.mob-signup-main-steps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  position: relative;
  margin-bottom: 20px;
}

.mob-signup-main-step {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 50%;
  font-size: 18px;
  font-weight: bold;
  background-color: #11989a;
  color: #fff;
}

.mob-signup-main-line {
  height: 2px;
  width: 32%;
  background-color: #11989a;
  position: absolute;
  top: 50%;
  z-index: -1;
  transition: width 0.5s ease;
}

.mob-signup-main-login-section {
  padding: 20px;
  border-radius: 10px;
}

.mob-signup-main-fields-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.mob-signup-main-field {
  flex: 1 1 100%;
  margin-bottom: 15px;
}

.mob-signup-main-field p {
  margin-bottom: 5px;
  font-weight: bold;
  font-family: Fraunces;
  color: #094344;
}

.mob-signup-main-field input {
  width: 100%;
  padding: 12px;
  border: 0px solid #ccc;
  font-size: 16px;
  border-bottom: 1px solid #ccc;
}

.mob-signup-main-otp-placeholder {
  color: #ccc;
}

.mob-signup-main-field input:focus {
  outline: none;
  border-color: #006241;
}

.mob-signup-main-validation-column {
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  border-radius: 6px;
}

.mob-signup-main-validation-message {
  color: #ccc;
  font-size: 12px;
  margin-top: 5px;
  display: flex;
  text-align: center;
  gap: 15px;
}

.mob-signup-navbar {
  display: flex;
  align-items: center;
  background-color: #11989a;
  color: white;
  padding: 0 25px;
  width: 100%;
  height: 50px;
  box-shadow: #11989a 3px 5px;
}

.mob-signup-back-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.mob-signup-main-login-head{
  font-weight: bold;
  font-family: Fraunces;
  color: #094344;
}
.mob-signup-title {
  flex-grow: 1;
  text-align: center;
  font-size: 18px;
  font-family: Fraunces;
font-weight: bold;
  color: #000;
}

.mob-signup-main-fields-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.mob-signup-main-fields-buttons p {
  margin-top: 10px;
  font-size: 14px;
  font-family: inter;
  color: #888;
}
.mob-signup-main-fields-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

.mob-signup-main-field {
  flex: 1 1 100%;
  margin-bottom: 15px;
}

.mob-signup-main-field p {
  margin-bottom: 5px;
  font-weight: bold;
  font-family: Fraunces;
  color: #094344;
}

.mob-signup-main-input {
  width: 100%;
  padding: 12px;
  border: 0px solid #ccc;
  font-size: 16px;
  border-bottom: 1px solid #ccc;
}

.mob-signup-main-field input:focus {
  outline: none;
  border-color: #006241;
}

.input-container {
  position: relative;
  width: 100%;
}

.eye-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
}

.mob-signup-main-validation-message {
  color: #ccc;
  font-size: 12px;
  margin-top: 5px;
  text-align: center;
}

.mob-signup-main-validation-message p {
  margin: 0;
}
.mob-signup-main-button {
  padding: 12px 24px;
  font-size: 18px;
  font-weight: bold;
  color: #000;
  border: none;
  font-family: Fraunces;
font-weight: 700;
  width: 80%;
  border-radius: 20px;
  background-color: #ceee36;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.mob-signup-main-button.inactive {
  background-color: #eee;
  cursor: not-allowed;
}
.mob-toast {
  background-color: #ceee36;
  color: #000000; 
  font-family: Arial, sans-serif; 
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
}