
.no-scroll {
  overflow: hidden;
}

.navbar-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.login-page-container {
  display: flex;
  flex-direction: column;
  height: 85vh;
  background-size: cover;
  background-image: url("../../images/Desktop-icons/Page 7.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
  position: relative;
}

.login-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #eee;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.user-icon {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.welcome-text {
  font-size: 24px;
  color: #094344;
  font-family: Fraunces;
  font-weight: bold;
  margin-bottom: 20px;
}

.login-signup-button {
  padding: 10px 20px;
  font-size: 16px;
  font-family: Fraunces;
  font-weight: 700;
  color: rgb(0, 0, 0);
  background-color: transparent;
  border: none;
  border-radius: 22px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-signup-button:hover {
  background-color: #ceee36;
}

.help-center-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.help-center-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.help-center-link {
  font-size: 18px;
  color: #000;
  font-family: inter;
  text-decoration: none;
}

.help-center-link:hover {
  text-decoration: underline;
}

.login-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.login-popup-box {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 50%;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ccc;
  color: #000;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.close-button:hover {
  background-color: #aaa;
}

.login-header h1 {
  margin: 0 0 20px;
  font-size: 24px;
  text-align: center;
  color: #094344;
  font-family: Fraunces;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.login-field {
  margin-bottom: 15px;
}

.login-field label {
  display: block;
  margin-bottom: 5px;
  color: #094344;
  font-family: Fraunces;
    font-weight: bold;
}

.login-field input {
  width: 100%;
  padding: 10px;
  font-family: inter;
  border-bottom: 2px solid #ceee36;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent; 
  border-right: 1px solid transparent;
}
.login-buttons-main{
  display: flex;
  width: 80%;
align-items: center;
justify-content: space-between;
}
.login-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  font-family: Fraunces;
font-weight: 700;
  cursor: pointer;
  background-color: #ceee36;
  color: #000;
}

.login-button:hover {
  background-color: #aad530;
}

@media (max-width: 768px) {
  .login-content {
    width: 90%;
    padding: 15px;
  }

  .user-icon {
    width: 80px;
    height: 80px;
  }

  .welcome-text {
    font-size: 20px;
  }

  .login-signup-button {
    font-size: 16px;
  }

  .login-popup-box {
    width: 90%;
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .user-icon {
    width: 60px;
    height: 60px;
  }

  .welcome-text {
    font-size: 18px;
  }

  .login-signup-button {
    font-size: 14px;
  }

  .login-popup-box {
    width: 95%;
    padding: 10px;
  }
}
.toast-message {
  position: fixed;
  top: 20px;
  left: 90%;
  transform: translateX(-50%);
  background-color: #ceee36;
  color: #000;
  padding: 10px 20px;
  width: 300px;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 2000;
  font-family: Fraunces;
  font-weight: bold;
}

.error-message{
  color: #e61e1e;
}