.mob-cart-full-width-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(#f9fde500, #f9fde5, #f9fde5, #f9fde500);
  font-family: Arial, sans-serif;
}

.mob-cart-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.mob-cart-back-button {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.mob-cart-page-title {
  font-size: 20px;
  font-weight: bold;
  font-family: Fraunces;
  color: #094344;
}

.mob-cart-logo {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.mob-cart-popup-menu {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 75%;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  padding: 20px;
  z-index: 2000;
}

.mob-cart-popup-menu.show {
  display: block;
}

.mob-cart-pop-menu-navbar-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.mob-cart-pop-menu-navbar-img {
  width: 12%;
  height: 10%;
}

.mob-cart-pop-menu-navbar-main li {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-size: 18px;
  cursor: pointer;
  font-family: Fraunces;
  color: #094344;
  width: 60%;
}

.mob-cart-close-popup {
  display: block;
  margin-top: 20px;
  padding: 10px;
  background-color: #ceee36;
  border: none;
  border-radius: 20px;
  font-family: Fraunces;
  font-weight: 700;
  color: #000;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
}

.mob-cart-ordertype-and-related-container {
  /* display: flex;
  justify-content: space-between; */
  width: 100%;
  margin: 15px 0;
}

.mob-cart-ordertypebox,
.mob-cart-related-products {
  flex: 1;
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  border: 2px solid #eee;
}

.mob-cart-ordertype-title {
  font-weight: bold;
  font-size: 18px;
  text-align: left;
  font-family: Fraunces;
  letter-spacing: 0;
  color: #094344;
}

.mob-cart-ordertype-options {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.mob-cart-order-button {
  background-color: #ffffff;
  color: #000;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  border-radius: 51px;
  font-weight: 700;
  font-family: Fraunces;
  border: 2px solid #eeee;
  font-size: 16px;
  cursor: pointer;
}

.mob-cart-order-button.active {
  background-color: #ceee36;
  color: #000;
}

.mob-cart-content-container {
  flex: 1;
  margin-top: 60px;
  padding: 20px;
  box-sizing: border-box;
}

.mob-cart-contact-info {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.mob-cart-whatsapp-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.mob-cart-items-container {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.mob-cart-items-container h2 {
  font-family: Fraunces;
  color: #094344;
  margin-bottom: 15px;
}

.mob-cart-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e0e0e0;
}

.mob-cart-product-image {
  width: 60px;
  height: 60px;
  margin-right: 15px;
  border-radius: 4px;
}

.mob-cart-product-details h3 {
  margin: 0 0 5px;
  font-size: 18px;
}

.mob-cart-item-description {
  margin: 0 0 5px;
  color: #888;
}

.mob-cart-item-price {
  margin: 0;
  color: #000;
  font-weight: bold;
}

.mob-cart-quantity-control {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.mob-cart-quantity-button {
  width: 30px;
  height: 30px;
  background-color: #ceee36;
  color: #000;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
}

.mob-cart-quantity-display {
  width: 30px;
  text-align: center;
}

.mob-cart-bill-details {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.mob-cart-bill-details h2 {
  color: #000;
  margin-bottom: 15px;
}

.mob-cart-item-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 16px;
}

.mob-cart-total-row {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
  margin-top: 15px;
  color: #000;
}

.mob-cart-footer {
  background-color: #fff;
  padding: 15px;
  border-top: 1px solid #e0e0e0;
}
.mob-cart-whatsapp-icon-p,
.mob-cart-whatsapp-icon-span {
  font-family: inter;
}
.mob-cart-checkout-button {
  width: 100%;
  padding: 15px;
  background-color: #ceee36;
  border: none;
  border-radius: 8px;
  color: #000;
  font-family: Fraunces;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
}

.mob-cart-full-width-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f5f5f5;
  font-family: Arial, sans-serif;
}

.mob-cart-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.mob-cart-back-button {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.mob-cart-page-title {
  font-size: 20px;
  font-weight: bold;
}

.mob-cart-logo {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.mob-cart-popup-menu {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 75%;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  padding: 20px;
  z-index: 2000;
}

.mob-cart-popup-menu.show {
  display: block;
}

.mob-cart-pop-menu-navbar-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.mob-cart-pop-menu-navbar-img {
  width: 12%;
  height: 10%;
}

.mob-cart-pop-menu-navbar-main li {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-size: 18px;
  cursor: pointer;
  width: 75%;
}

.mob-cart-close-popup {
  display: block;
  margin-top: 20px;
  padding: 10px;
  background-color: #ceee36;
  border: none;
  border-radius: 20px;
  font-family: Fraunces;
  font-weight: 700;
  color: #000;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
}

.mob-empty-cart-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  margin-top: 60px;
}

.mob-empty-cart-image {
  width: 50%;
  max-width: 200px;
  margin-bottom: 20px;
}

.mob-empty-cart-button {
  padding: 10px 20px;
  background-color: #ceee36;
  color: #000;
  border: none;
  border-radius: 29px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  font-family: Fraunces;
  font-weight: 700;
}

.mob-empty-cart-emptycart-text {
  margin-top: 20px;
  width: 68%;
  font-family: inter;
}

.mob-empty-cart-emptycart-head {
  margin-top: 20px;
  font-family: Fraunces;
  color: #094344;
}

.mob-emmpty-cart-full-width-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(#f9fde500, #f9fde5, #f9fde5);
  font-family: Arial, sans-serif;
}
