.mob-influencer-form-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(#f9fde500, #f9fde5, #f9fde5);
    background-color: #f9f9f9;
    font-family: "Poppins", sans-serif;
  }
  
  .mob-order-navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .mob-back-button,
  .mob-logo {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  
  .mob-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f9fde5;
    min-height: 90vh;
    width: 100%;
    position: relative;
    margin-top: 20px;
  }
  
  .mob-form-header-h3 {
    font-family: Fraunces;
    color: #333;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-top: 30px;

  }
  
  .mob-form {
    background-color: #fff;
    padding: 20px;
    width: 90%;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .mob-form-group {
    margin-bottom: 15px;
  }
  
  .mob-form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 700;
    font-family: Fraunces;
    color: #333;
  }
  
  .mob-form-group input,
  .mob-form-group textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .mob-form-group input:focus,
  .mob-form-group textarea:focus {
    outline: none;
    border-color: #66afe9;
  }
  
  .mob-form-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .form-actions-submit{
    background-color: #ceee36;
    color: #000;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    font-family: 'Fraunces', sans-serif;
    font-weight: 700;
  }
  
  .mob-close-button-secondary {
    background-color: transparent;
    color: #000;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    border: 1px solid #f44336;
    cursor: pointer;
    font-size: 14px;
    font-family: 'Fraunces';
    font-weight: 700;
  }
  
  .mob-close-button-secondary:hover {
    background-color: #f44336;
  }
  
  .popup-menu {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    width: 75%;
    height: 100%;
    background-color: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
    padding: 20px;
    z-index: 2000;
  }
  
  .popup-menu.show {
    display: block;
  }
  
  .popup-menu ul {
    list-style: none;
    padding: 15px 0;
    margin: 0;
  }
  
  .pop-menu-navbar-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .pop-menu-navbar-img {
    width: 12%;
    height: 10%;
  }
  
  .popup-menu ul li {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin-bottom: 20px;
    font-size: 18px;
    cursor: pointer;
    font-family: Fraunces;
    color: #094344;
    width: 60%;
  }
  
  .close-popup {
    display: block;
    margin-top: 20px;
    padding: 10px;
    background-color: #ceee36;
    border: none;
    border-radius: 20px;
    font-family: Fraunces;
    font-weight: 700;
    color: #000;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
  }
  
  .close-popup:hover {
    background-color: #b0d12e;
  }
  
  @media (max-width: 768px) {
    .mob-form-header-h3 {
      font-size: 1.5rem;
    }
  
    .mob-form-group input,
    .mob-form-group textarea {
      font-size: 12px;
    }
  
    .mob-form-actions button {
      font-size: 12px;
    }
  }
  