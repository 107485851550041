.mob-menu-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: linear-gradient(#f9fde500, #f9fde5, #f9fde500);
  padding-top: 50px;
}
.mob-search-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 0; */
  background-color: white;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}
.mob-menu-popup-body-head-sec{
  display: flex;
  background-color:#f9fde5 ;

}
.mob-menu-popup-body {
  max-height: 70vh; /* Limit the height of the body */
  overflow-y: auto; /* Enable vertical scrolling */
  padding-right: 10px; /* Optional: prevent content from touching the scrollbar */
}
.mob-menu-popup-body::-webkit-scrollbar {
  width: 5px; /* Set the scrollbar width */
  height: 60px; /* Set the height for horizontal scrollbars */
}

.mob-menu-popup-body::-webkit-scrollbar-thumb {
  background-color: #ceee36; /* Set the scrollbar thumb color */
  border-radius: 10px; /* Optional: Round the edges of the scrollbar thumb */
}

.mob-menu-popup-body::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Optional: Set the scrollbar track color */
}

.mob-menu-popup-body-image{
  max-width: 20px;
  max-height: 20px;
}
.mob-search-header {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f1f1f1;
  border-bottom: 1px solid #ccc;
}

.mob-search-input {
  flex-grow: 1;
  padding: 8px;
  margin-left: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.mob-menu-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0px 25px;
  z-index: 1000;
}

.mob-back-button,
.mob-search-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.mob-sidebar {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  height: 60px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow-x: hidden;
  display: flex;
  gap: 10px;
  white-space: nowrap;
  z-index: 999;
}

.mob-main-categories {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 15px;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.mob-main-categories::-webkit-scrollbar {
  display: none;
}

.mob-main-category {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  transition: background-color 0.3s, transform 0.3s;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.mob-main-category:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.mob-main-category.selected {
  background-color: #ceee36;
}
.mob-menu-navhead{
  font-family: Fraunces;
  color: #094344;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0;
    text-transform: uppercase;
    opacity: 1;
}

.mob-main-category p {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
  font-family: Fraunces;
  color: #333;
}

.mob-menu-body {
  position: fixed;
  top: 110px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  overflow-y: auto;
}

.mob-menu-categories {
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  overflow-x: auto;
  gap: 10px;
}

.mob-category-list {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.mob-category-list::-webkit-scrollbar {
  display: none;
}

.mob-category-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mob-category-item:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.mob-category-item.selected {
  border: 1px solid #ceee36;
}

.mob-category-item img {
  border-radius: 50%;
  max-width: 100%;
  height: 55px;
  object-fit: cover;
}

.mob-category-item p {
  margin-top: 5px;
  color: #333;
  font-weight: bold;
  font-family: inter;
}

.mob-menu-icecream-list {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.mob-menu-offer-item {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
  width: 96%;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.mob-menu-offer-item:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.mob-menu-offer-image-container {
  flex: 0 0 33%; /* Takes up 1/3 of the width */
  margin-right: 20px;
}

.mob-menu-offer-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
}

.mob-menu-offer-details {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.mob-menu-offer-name {
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-family: inter;
  color: #333;
  font-weight: bold;
}

.mob-menu-offer-description {
  color: #777;
  font-size: 1rem;
  margin-bottom: 10px;
  font-family: inter;
}

.mob-menu-offer-end-label {
  background-color: #ff5733;
  color: white;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: bold;
  align-self: flex-start;
  margin-bottom: 10px;
}

.mob-menu-offer-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mob-menu-offer-price-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.mob-menu-offer-old-price {
  font-size: 0.9rem;
  color: #888;
  text-decoration: line-through;
}

.mob-menu-offer-new-price {
  font-size: 1.2rem;
  color: #d9534f;
  font-weight: bold;
}

.mob-menu-add-cart-button {
  width: 100px;
  height: 42px;
  background: #ceee36;
  border-radius: 153px;
  border: none;
  font-weight: 700;
  font-family: Fraunces;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}
.mob-menu-icecream-item {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
  width: 96%;
  box-sizing: border-box;
}

.mob-menu-icecream-item:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.mob-menu-icecream-image-container {
  flex: 0 0 auto;
  margin-right: 20px;
}

.mob-menu-icecream-image {
  width: 200px;
  height: 150px;
  max-width: 110px;
  object-fit: cover;
  border-radius: 10px;
}

.mob-menu-icecream-details {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.mob-menu-icecream-name {
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-family: inter;
  color: #333;
  font-weight: bold;
}

.mob-menu-icecream-description {
  color: #777;
  font-size: 1rem;
  margin-bottom: 10px;
  font-family: inter;
  overflow: hidden;

}
.mob-menu-toggle-description-button {
  background: transparent;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  padding: 0;
  align-self: flex-start;
  display: inline-block;
}

.mob-menu-toggle-description-button:hover {
  text-decoration: none;
}


.mob-menu-icecream-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mob-menu-icecream-price {
  font-size: 1.1rem;
  font-family: inter;
  color: #333;
  font-weight: bold;}

.mob-menu-add-cart-button {
  width: 100px;
  height: 42px;
  background: #ceee36;
  border-radius: 153px;
  border: none;
  font-weight: 600;
  display: flex;
  font-weight: 700;
    font-family: Fraunces;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: auto;
  cursor: pointer;
}

.mob-menu-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.mob-menu-popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  height: 88%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.mob-menu-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.mob-menu-popup-close-button {
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  font-family: Fraunces;
  border-radius: 20px;
  font-weight: 700;
}

.mob-menu-popup-body p {
  margin: 10px ;
}

.mob-menu-popup-body label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}

.mob-menu-popup-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: white;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
width: 90%;
  position: absolute;
  bottom: 6%;
  left: 20px;
}
.mob-menu-nav-icon{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.mob-menu-popup-add-button {
  background: #ceee36;
  border: none;
  border-radius: 153px;
  padding: 10px 20px;
  font-weight: 600;
  display: flex;
  width: 100%;
  font-family: Fraunces;
  font-weight: 700;
  gap: 25px;
  cursor: pointer;
  justify-content: center;
}

.mob-menu-popup-body-head {
  display: flex;
  width: 100%;
  background-color: #f9fde5;
  margin-bottom: 11px;
}
.mob-menu-out-of-stock {
  color: red;
  font-weight: bold;
  font-size: 1.1rem;
  font-family: inter;
}
.mob-menu-icecream-item.out-of-stock {
  opacity: 0.6;
  pointer-events: none;
}
.mob-custom-radio-main input[type="radio"],
.mob-custom-checkbox-main input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}
.mob-custom-checkbox-main {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.mob-custom-checkbox-sub {
  display: flex;
  align-items: center;
  gap: 8px;
}

.mob-custom-radio-main {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.mob-custom-radio-sub {
  display: flex;
  align-items: center;
  gap: 8px;
}
.mob-custom-checkbox-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.mob-custom-checkbox {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.mob-menu-popup-header-name {
  max-width: 40%;
  overflow: hidden;
  color: #094344;

  font-family: Fraunces;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mob-menu-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  gap: 30px;
  justify-content: space-between;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.mob-menu-footer-button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
}

.mob-menu-footer-button:active {
  transform: scale(0.98);
}

.footer-button-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.dine-in {
  background: #ceee36;
    color: #000;
    font-family: Fraunces;
  font-weight: 700;
  border: 2px solid #ceee36;

}
.mob-menu-pop-option-head{
  display: flex;
  width: 100%;
  background-color: #f9fde5;
  margin-bottom: 11px;
  font-size: 14px;
  font-family: Fraunces;
  font-weight: 600;
}
.take-away {
  background-color: #fff;
  color: #000;
  font-family: Fraunces;
font-weight: 700;
border: 2px solid #ceee36;
}

@media (max-width: 320px) {
  .mob-menu-footer-button {
    font-size: 14px;
  }
  
  .footer-button-icon {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }
}
.mob-menu-quan-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Ensures equal spacing between all elements */
}

.mob-menu-quan-button {
  background-color: #ceee36;
  color: #000;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
}

.mob-menu-quan-minus {
  border-radius: 4px 0 0 4px;
}

.mob-menu-quan-plus {
  border-radius: 0 4px 4px 0;
}

.mob-menu-quan-display {
  width: 30px;
  text-align: center;
  font-size: 16px;
}
.pmob-custom-checkbox-sub{
  margin-right: 350px;

}