
.admin-signup-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-image: url('../../images/Desktop-icons/Page\ 8.png'); 
  background-size: cover;
  background-position: center;
}

.admin-signup-navbar {
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: #ffffff;
  color: #000000;
  padding: 10px 20px;
  font-size: 20px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.admin-signup-logo {
  width: 50px;
  height: auto;
}

.admin-signup-back-button {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.admin-signup-title {
  font-size: 24px;
  font-weight: bold;
}

.admin-signup-content {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 80px);
  padding: 20px;
}

.admin-signup-form {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 40px;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.admin-signuphead{
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.admin-signup-field {
  display: flex;
  flex-direction: column;
}

.admin-signup-field label {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
}

.admin-signup-field input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 16px;
}

.admin-signup-submit-button {
  background-color: #ceee36;
  color: #ffffff;
  border: none;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.admin-signup-submit-button:hover {
  background-color: #b0d12e;
}

@media (max-width: 768px) {
  .admin-signup-navbar {
    gap: 10px;
    padding: 10px;
    font-size: 18px;
  }

  .admin-signup-title {
    font-size: 20px;
  }

  .admin-signup-form {
    padding: 20px;
    max-width: 300px;
  }

  .admin-signup-submit-button {
    padding: 10px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .admin-signup-navbar {
    gap: 5px;
    padding: 5px;
    font-size: 16px;
  }

  .admin-signup-title {
    font-size: 18px;
  }

  .admin-signup-form {
    padding: 15px;
    max-width: 100%;
  }

  .admin-signup-submit-button {
    padding: 8px;
    font-size: 12px;
  }
}
