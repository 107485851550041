
.order-full-width-container {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.sidemainlogos {
  position: fixed;
  z-index: 100;
  width: 10%;
  height: 9%;
}

.cart-navbar {
  display: flex;
  align-items: center;
  gap: 30px;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  padding: 10px 15px 10px 30px;
  font-size: 20px;
  box-shadow: 0 3px 0px rgba(0, 0, 0, 0.08235294117647059);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.cart-back-button {
  width: 24px;
  height: 24px;
  margin-left: 14%;
  cursor: pointer;
}

.order-page-content {
  display: flex;
  flex: 1;
  margin-top: 60px;
  width: 98%;
}

.sticky-navbar {
  background-color: #f9fde5;
  position: sticky;
  top: 0;
  left: 0;
  width: 200px;
  height: 100%;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 18px;
  display: flex;
  flex-direction: column;
}

.navbar-item {
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  font-family: Fraunces;
  font-size: 14px;
  font-weight: bold;
  color: #094344;
  text-transform: uppercase;
  justify-content: space-between;
  cursor: pointer;
  transition: color 0.3s, border-bottom 0.3s;
}

.navbar-item.selected {
  color: #000;
  border-radius: 19px;
  background-color: #ceee36;
}

.navbar-item:hover {
  color: #ceee36;
}

.navbar-img {
  width: 15%;
  height: 100%;
}

.order-content {
  background: linear-gradient(#f9fde500, #f9fde500, #f9fde5);
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: auto;
}

.order-past-order {
  width: 80%;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  padding: 20px;
}

.order-details {
  display: flex;
  align-items: center;
}

.order-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 20px;
}

.order-info {
  flex: 1;
}

.order-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #094344;
  font-family: Fraunces;
}

.order-id {
  font-size: 14px;
  color: #888888;
  font-family: inter;
}

.order-date {
  font-size: 14px;
  color: #000;
  font-family: inter;
  font-weight: 400;
  padding: 5px 0px;
}

.order-dotted-line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dotted #cccccc;
  margin: 10px 0;
}

.order-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reorder-button {
  background-color: #ceee36;
  color: #000;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: 700;
  font-family: Fraunces;
}

.reorder-button:hover {
  background-color: #b0d12e;
}

.order-price {
  font-size: 18px;
  font-weight: bold;
  color: #094344;
  font-family: Fraunces;
}
