.career-main-section {
  width: 100%;
  background: linear-gradient(#f9fde500, #f9fde5, #f9fde500);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
}

.career-main-image {
  max-width: 60%;
  border-radius: 15px;
  height: 100%;
  max-height: 250px;
  margin-bottom: 40px;
}

.career-content-h1 {
  font-size: 3rem;
  color: #094344;
  font-family: Fraunces;
  display: flex;
  align-content: space-between;
  justify-content: center;
  margin-top: 20px;
}


.career-content {
  text-align: center;
  width: 80%;
  max-width: 800px;
}

.career-description {
  font-family: "Inter", sans-serif;
  font-size: 1.2rem;
  line-height: 1.6;
  color: #000;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.toggle-container {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.career-toggle-button {
  background-color: transparent;
  border: none;
  color: #11989a;
  cursor: pointer;
  font-size: 1rem;
  font-family: "Fraunces", serif;
  margin-left: 10px;
  display: inline;
}

.pop-career-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.pop-career-content {
  background: #f9fde5;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.pop-career-buttonsec {
  display: flex;
  justify-content: space-between;
}
.pop-career-content h2 {
  margin-bottom: 15px;
}
.pop-career-buttonsubmit {
  background-color: #ceee36;
  color: #000;
  border: none;
  padding: 15px 30px;
  border-radius: 20px;
  font-weight: 700;
  font-family: "Fraunces", serif;
  cursor: pointer;
  font-size: 1rem;
}
.pop-career-content label {
  display: block;
  margin-bottom: 5px;
  font-family: inter;
}

.pop-career-content input,
.pop-career-content textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.pop-career-content-head {
  font-family: "Fraunces", serif;
  font-size: 2rem;
  color: #11989a;
}

.pop-career-buttonclose {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
  border-radius: 15px;
  width: 95px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  font-family: Fraunces;
}
.pop-career-buttonclose:hover {
  background-color: #11989a;
}

.career-toggle-button:hover {
  text-decoration: underline;
}

.career-heading {
  font-family: "Fraunces", serif;
  font-size: 2.5rem;
  color: #11989a;
  margin-bottom: 20px;
}

.career-email {
  font-family: "Inter", sans-serif;
  font-size: 1.1rem;
  margin-bottom: 30px;
}

.career-email a {
  color: #11989a;
  text-decoration: none;
}

.career-apply-button {
  background-color: #ceee36;
  color: #000;
  border: none;
  padding: 15px 30px;
  border-radius: 20px;
  font-weight: 700;
  font-family: "Fraunces", serif;
  cursor: pointer;
  font-size: 1rem;
}

.career-apply-button:hover {
  background-color: #b0d12e;
}
.career-images {
  display: flex;
  justify-content: center;
  gap: 30px; 
  margin-top: 20px;
}

.career-images-1 .career-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
}

.career-images-2 .career-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.career-image {
  max-width: 200px;
  height: 200px;  
  max-height:200px ;
  border-radius: 15px;
}


@media (max-width: 768px) {
  .career-main-section {
    padding: 30px 0;
  }
  .career-images-1 .career-image, .career-images-2 .career-image {
    width: 200px;
    height: 200px;
  }
  .career-main-image {
    width: 90%;
  }

  .career-heading {
    font-size: 2rem;
  }

  .career-description {
    font-size: 1rem;
  }

  .career-apply-button {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
}
