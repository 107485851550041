.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar-logo {
  height: 40px;
  margin-right: 20px;
}

.navbar-center {
  flex: 1;
  display: flex;
  margin-left: 9%;
  justify-content: flex-start;
}

.navbar-links {
  list-style: none;
  display: flex;
  margin-left: 20%;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  font-size: 1rem;
}

.navbar-links a {
  text-decoration: none;
  color: #333;
  transition: color 0.3s;
}

.navbar-links a:hover {
  color: #ceee36;
}

.navbar-right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.navbar-search {
  padding: 5px 10px 5px 40px;
  border: 1px solid #ddd;
  border-radius: 13px;
  outline: none;
  background-image: url("../../../images/Desktop-icons/search-lens.svg");
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 15px;
}

.navbar-login {
  padding: 5px 15px;
  background-color: #ceee36;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  color: #fff;
  transition: background-color 0.3s;
}

.navbar-login:hover {
  background-color: #b3d62c;
}

.navbar-links-head {
  font-family: Fraunces;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  color: #094344;
  text-transform: uppercase;
  opacity: 1;
  cursor: pointer;
}
.profile-icon-head{
  color: #094344;
  font-family: Fraunces;
  font-weight: bold;

}

.navbar-user-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.navbar-user {
  position: relative;
}

.user-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 150px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

.user-dropdown p {
  margin: 0;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.user-dropdown p:hover {
  background-color: #f0f0f0;
}

.profile-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.profile-popup {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 40%;
  height: 65%;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-family: Fraunces;
font-weight: 700;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.profile-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.profile-popup h2 {
  margin: 10px 0;
}

.profile-popup p {
  margin: 5px 0;
}

.profile-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.profile-form label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  font-family: Fraunces;
  color: #094344;
}

.user-dropdown-p {
  font-size: 0.9rem;
  font-family: Fraunces;
  color: #094344;
}

.profile-form input {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #ceee3624;
  border-bottom: 1px solid #eee;
}

.profile-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.save-button,
.cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 700;
  font-family: Fraunces;
  transition: background-color 0.3s;
}

.save-button {
  background-color: #ceee36;
  color: #000;
}

.save-button:hover {
  background-color: #b3d62c;
}

.cancel-button {
  background-color: #ddd;
  color: #000;
}

.cancel-button:hover {
  background-color: #ccc;
}

.password-error {
  color: #ff0000;
  font-size: 14px;
  margin-top: 5px;
}

.save-button.disabled {
  opacity: 0.5;
  pointer-events: none;
}