.mob-dine-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  .mob-dine-popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    background: none;
    border: none;
    cursor: pointer;
    color: #555; /* Adjust color as needed */
}

.mob-dine-popup {
    position: relative; /* Make sure the close button is positioned within the popup */
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    width: 90%;
    max-width: 800px;
    height: 45%;
    display: flex;
    flex-direction: column;
}
 
  .mob-dine-popup-header {
    margin: 18px auto 30px auto;
    font-size: 22px;
    font-family: Fraunces;
    font-weight: 700;
    color: #094344;
    width: 90%;
    text-align: center;
  }
  
  .mob-dine-popup-options {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 30px;
  }
  
  .mob-dine-popup-button {
    padding: 10px 20px;
    background: #fff;
    color: #000;
    border: none;
    border-radius: 24px;
    cursor: pointer;
    font-size: 18px;
    font-family: Fraunces;
    font-weight: 700;
    border: 1px solid #ceee36;
    margin-top: 15px;
  }
  
  .mob-dine-popup-button:hover {
    background: #ceee36;
  }
  
  