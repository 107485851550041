.contact-help-section {
    width: 100%;
    background-color: #F9FDE5;
    display: flex;
    justify-content: center;
    padding: 20px 0;
}

.contact-help-content {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
}

.contact-help-left {
    display: flex;
    align-items: center;
}

.contact-help-image {
    width: 50px;
    height: 50px;
    margin-right: 20px;
}

.contact-help-left p {
    margin: 5px 0 0;
    font-family: inter;
}

.contact-help-right {
    display: flex;
    align-items: center;
}

.contact-write-to-us {
    background-color: #ceee36;
    color: #000;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    font-weight: 700;
    font-family: Fraunces;
    cursor: pointer;
    font-size: 14px;
    margin-right: 20px;
}

.contact-write-to-us:hover {
    background-color: #b0d12e;
}

.contact-phone-contact {
    display: flex;
    align-items: center;
}

.contact-phone-image {
    width: 30px;
    height: 34px;
    margin-right: 10px;
}

.contact-phone-number {
    font-size: 14px;
    font-family: inter;
}

.contact-about-us-section {
    padding: 40px 20px;
    background: linear-gradient(#F9FDE500, #F9FDE5, #F9FDE500);
}

.contact-content-block {
    max-width: 800px;
    margin: 0 auto 20px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}
.contact-aboutus-head{
    font-size: 51px;
    color: #094344;
    font-family: Fraunces;
}
.contact-content-block h2 {
    font-size: 1.8rem;
    color: #11989a;
    font-family: Fraunces;
    margin-bottom: 10px;
}

.contact-content-block p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 10px;
    font-family: inter;
}

@media (max-width: 768px) {
    .contact-help-content {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .contact-help-left,
    .contact-help-right {
        margin-bottom: 10px;
    }

    .contact-write-to-us {
        margin-bottom: 10px;
    }

    .contact-content-block {
        padding: 15px;
    }

    .contact-content-block h2 {
        font-size: 1.5rem;
    }

    .contact-content-block p {
        font-size: 0.9rem;
    }
}


.contact-aboutus-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.contact-aboutus-popup-content {
    background-color: #fff;
    padding: 20px;
    width: 50%;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: relative;
}

.contact-aboutus-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 24px;
    color: #888;
}

.contact-aboutus-close:hover {
    color: #333;
}


.contact-aboutus-form-group {
    margin-bottom: 20px;
}
.contact-aboutus-input,
.contact-aboutus-select,
.contact-aboutus-textarea {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.contact-aboutus-input::placeholder,
.contact-aboutus-textarea::placeholder {
    color: #999;
    font-size: 14px;
}
.contact-aboutus-form-group label {
    display: block;
    margin-bottom: 5px;
    font-family: Fraunces;
    color: #094344;
}

.contact-aboutus-input,
.contact-aboutus-select,
.contact-aboutus-textarea {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.contact-aboutus-input:focus,
.contact-aboutus-select:focus,
.contact-aboutus-textarea:focus {
    outline: none;
    border-color: #66afe9;
}

.contact-aboutus-select {
    appearance: none;
    -webkit-appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23000000' width='24px' height='24px'%3E%3Cpath d='M7 10l5 5 5-5H7z'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 10px center;
}

.contact-aboutus-submit {
    background-color: #ceee36;
    color: #000;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    font-family: Fraunces;
  font-weight: 700;
}
.contact-hero-section {
    background-color: #f9fde5;
    padding: 8px 0;
    text-align: center;
  }
  
  .contact-hero-content {
    max-width: 800px;
    margin: 0 auto;
  }

  .contact-hero-content h1 {
    font-size: 3rem;
    font-size: 41px;
    color: #094344;
    font-family: Fraunces;
  }
  
  .contact-hero-content p {
    font-size: 1.2rem;
    color: #666;
    font-family: inter;
  }
  
.contact-aboutus-submit:hover {
    background-color: #b0d12e;
}
.contact-blog-image {
    width: 100%;
    height: auto;
    margin-top: 10px;
    border-radius: 8px;
  }
  
  .contact-aboutus-popuphead{
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #094344;
    font-family: Fraunces;
  }