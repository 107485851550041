.Mob-mainfooter {
  width: 100%;
  padding: 10px;
  position: relative;
  background: linear-gradient(to bottom, #f9fde500, #f9fde5);
}

.Mob-sideborder,
.Mob-leftborder,
.Mob-leftsideborder {
  position: absolute;
  max-width: 100%;
  height: auto;
}

.Mob-sideborder {
  bottom: 0;
  left: 0;
  z-index: 1;
}

.Mob-leftborder {
  bottom: 0;
  right: 0;
  z-index: 1;
}

.Mob-leftsideborder {
  bottom: 0;
  right: 0;
  z-index: 1;
}

.Mob-downborder {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 100%;
  height: auto;
  z-index: 0;
}

.Mob-footersub-sectionmain {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 0 10px;
  z-index: 2;
  position: relative;
}

.Mob-footer-center-section {
  text-align: center;
  padding: 10px;
  z-index: 2;
  position: relative;
}

.Mob-footer-middle-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 10px 0;
  z-index: 2;
  position: relative;
}

.Mob-footer-left-section {
  width: 48%;
  text-align: left;
  padding: 10px;
}

.Mob-footer-right-section {
  width: 48%;
  text-align: center;
  padding: 10px;
  z-index: 2;
}

.Mob-footer-left-sectionp {
  font-family: "Poppins", sans-serif;
  color: #094344;
  font-size: 16px;
  margin-bottom: 10px;
}

.Mob-footer-social-icons {
  display: flex;
  /* justify-content: center; */
  gap: 20px;
}

.Mob-footer-right-sectionhead {
  font-family: "poppins", sans-serif;
  color: #094344;
  font-size: 16px;
  /* font-weight: bold; */
  margin-bottom: 10px;
}

.Mob-footer-right-sectionp {
  color: #75807b;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  margin: 5px 0;
}

.Mob-footer-center-contacttext,
.Mob-footer-center-contactnumber,
.Mob-footer-centersec-contactnumber,
.Mob-footer-center-contactcontent {
  font-family: "Poppins", sans-serif;
  color: #75807b;
  font-size: 14px;
  text-align: center;
}

.Mob-footer-center-contactnumber {
  font-size: 20px;
  color: #11989a;
  font-weight: bold;
  margin: 5px 0;
}

.Mob-footer-centersec-contactnumber {
  font-size: 16px;
}

.Mob-footer-copyright {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #75807b;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  padding-top: 10px;
  z-index: 2;
  position: relative;
}

.Mob-footer-copyright hr {
  width: 80%;
  margin-bottom: 10px;
  border-color: #eeeeee;
}

.Mob-footmainlogo {
  width: 100px;
  height: auto;
  margin-bottom: 10px;
}

.Mob-social-foot-icon {
  width: 30px;
  height: auto;
}
