.mob-blog-page {
  background-color: #f9fde5;
}

.mob-blog-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.mob-blog-back-button,
.mob-blog-menu-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.mob-blog-title {
  font-size: 18px;
  font-weight: bold;
  font-family: Fraunces;
  color: #094344;
}

.mob-blog-content {
  padding: 60px 10px 10px;
}

.mob-blog-section {
  margin-bottom: 20px;
}

.mob-blog-nav {
  text-align: center;
  margin-bottom: 10px;
}

.mob-blog-nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
}

.mob-blog-nav li {
  margin: 0 10px;
  cursor: pointer;
  font-size: 1rem;
  font-family: Fraunces;
  color: #094344;
  text-transform: uppercase;
  padding-bottom: 5px;
  border-bottom: 2px solid transparent;
  transition: color 0.3s ease, border-bottom 0.3s ease;
}

.mob-blog-nav li:hover {
  color: #ceee36;
}

.mob-blog-nav li.mob-blog-selected {
  border-bottom-color: #ceee36;
  font-weight: bold;
}

.mob-blog-posts {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Increased gap between blogs */
}
.mob-blog-posts-trends{
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  text-align: center;

}

.mob-blog-post {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.mob-blog-post-gallery{
  max-width: 259px;
  border: 2px solid #ceee36;

  max-height: 288px;
}
.mob-blog-post:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}


.mob-blog-post img {
  display: block;
  margin: 0 auto;
  max-width: 200px; 
  max-height: 200px;
  border-radius: 8px;
  margin-bottom: 8px;
}

.mob-blog-post-title {
  font-size: 28px;
  margin-bottom: 5px;
  font-family: Fraunces;
  color: #11989a;
  font-weight: 700;
  text-align: center;  
  margin: 20px 0;  
}
.mob-blog-post-content {
  color: #333333;
  line-height: 1.4;
  font-weight: 500;
  font-size: 16px;
  font-family: inter;
}

.mob-blog-popup-menu {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 75%;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  padding: 20px;
  z-index: 2000;
}

.mob-blog-popup-menu.mob-blog-show {
  display: block;
}

.mob-blog-popup-menu-nav {
  list-style: none;
  padding: 15px;
  margin: 0;
}

.mob-blog-popup-menu-nav li {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  color: #000;
  margin-bottom: 20px;
  cursor: pointer;
  font-family: Fraunces;
}

.mob-blog-popup-menu-icon {
  width: 32px;
  height: 32px;
  margin-left: 10px;
}

.mob-blog-close-popup {
  background-color: #ceee36;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: Fraunces;
}

.mob-blog-close-popup:hover {
  background-color: #ceee36;
  color: #094344;
}
