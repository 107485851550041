.policy-main-section {
    width: 100%;
    background: linear-gradient(#f9fde500, #f9fde5,#f9fde5, #f9fde500);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
  }
  
  .policy-content {
    text-align: left;
    width: 80%;
    max-width: 800px;
  }
  .toggle-container {
    display: inline-block; 
    margin-left: 10px; 
  }
  
  .policy-toggle-button {
    background-color: transparent;
    border: none;
    color: #11989a; 
    cursor: pointer;
    font-size: 1rem; 
    font-family: "Fraunces", serif;
  }
  
  .policy-toggle-button:hover {
    text-decoration: underline; 
  }
  
  .policy-heading {
    font-family: "Fraunces", serif;
    font-size: 2.5rem;
    color: #11989a;
    margin-bottom: 30px;
  }
  
  .policy-subheading {
    font-family: "Fraunces", serif;
    font-size: 1.2rem;
    color: #11989a;
    margin-bottom: 20px;
  }
  
  .policy-text {
    font-family: "Inter", sans-serif;
    font-size: 1.2rem;
    line-height: 1.6;
    color: #000;
    margin-bottom: 20px;
  }
  .policy-images {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .policy-images-single img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .policy-images-multiple img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    margin: 0 10px;
  }
  
  .policy-image {
    transition: transform 0.3s ease;
  }
  
  .policy-image:hover {
    transform: scale(1.1);
  }
  

  
  @media (max-width: 768px) {
    .policy-main-section {
      padding: 30px 0;
    }
    .policy-images-single img, 
    .policy-images-multiple img {
      width: 150px;
      height: 150px;
    }
    .policy-heading {
      font-size: 2rem;
    }
  
    .policy-subheading {
      font-size: 1.5rem;
    }
  
    .policy-text {
      font-size: 1rem;
    }
  }
  