.Mob-home-bottombackground {
  background: #f9fde5;
  padding: 0px 20px;
  position: relative;
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Mob-home-icecream-text-head {
  font-weight: 700;
  font-size: 30px;
  color: #094344;
  font-family: "Fraunces 144pt SuperSoft 144pt SuperSoft NonWonky";
  margin-bottom: 20px;
}
.Mob-home-icecream-text-passion {
  display: block;
  font-weight: 700;
  font-size: 45px;
  color: #094344;
  font-family: "Fraunces 144pt SuperSoft 144pt SuperSoft NonWonky";
  margin-top: 10px;
}
.Mob-home-text-content {
  position: relative;
  z-index: 3;
}

.Mob-home-icecream-text-p {
  font-family: "Poppins", sans-serif;
  font-weight: medium;
  color: #000000;
  opacity: 0.6;
  font-size: 16px;
  margin-bottom: 20px;
}
.Mob-home-topwave {
  position: relative;
  top: 5px;
  left: 0;
  width: 100%;
  height: auto;
  z-index: -1;
}

.Mob-home-bottombackground-head-text {
  color: #094344;
  font-family: Fraunces 144pt SuperSoft 144pt SuperSoft NonWonky;
  font-size: 40px;
  font-weight: bold;
  opacity: 1;
  margin-top: 10px;
}

.Mob-home-bottombackground-head-scoope {
  color: #094344;
  font-size: 16px;
  font-family: Poppins;
  opacity: 0.6;
  margin: 10px 0;
}

.Mob-home-products-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin: 0 auto;
}

.Mob-home-icecreams {
  width: 166px;
  height: 42px;
  background: #ceee36;
  border-radius: 153px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Fraunces 144pt SuperSoft 144pt SuperSoft NonWonky;
  text-align: center;
  letter-spacing: 0px;
  color: #094344;
  text-transform: uppercase;
  opacity: 1;
}

.Mob-home-secondimage {
  margin-right: 95%;
  width: 30%;
  height: 30%;
  opacity: 1;
}



.Mob-home-discoverbuttonsecondice {
  width: 166px;
  height: 42px;
  margin-bottom: 10%;
  background: #ceee36;
  border-radius: 153px;
  opacity: 1;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.Mob-home-discover-button-onice {
  width: 166px;
  height: 42px;
  background: #ceee36;
  border-radius: 153px;
  opacity: 1;
  font-weight: 600;
  display: flex;
  color: #094344;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 100%;
  text-align: left;
  left: 13%;
  padding: 10px 0px;
  font-weight: bold;
  cursor: pointer;
}

.Mob-home-ice-made-img-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 15px;
  overflow: hidden;
}

.Mob-home-image-container-bluediv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 30px;
}

/* .Mob-home-thirdimage {
    position: absolute;
    top: 70%;
    left: 58%;
    transform: translateX(2%);
    z-index: 0;
    width: 40%;
    height: auto;
  }
  
  
  
  .Mob-home-logoimage {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 78%;
    right: 11%;
  } */

/* .Mob-home-icecream-text-passion {
    display: block;
    font-weight: 700;
    font-size: 51px;
    color: #094344;
    font-family: "Fraunces 144pt SuperSoft 144pt SuperSoft NonWonky";
    margin-top: 10px;
  }
   */

.Mob-home-icecream-products-slider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.Mob-home-icecream-products-bottombackground {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  width: 89%;
  gap: 20px;
}
.mob-homepage-carousel-container {
  width: 100vw;
  overflow: hidden;
}

.mob-carousel-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px; /* Space between slides */
}

.mob-carousel-image-container {
  position: relative;
  width: 100%;
  cursor: pointer; /* Shows pointer when hovered */
}

.mob-carousel-image {
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: cover;
  border-radius: 15px;
}

.mob-carousel-text-container {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  display: block; /* Always displayed */
}

.mob-carousel-text {
  font-size: 16px;
  font-weight: bold;
  color: black; /* Black text */
}

/* Optional: Add hover effect for the image */
.mob-carousel-image-container:hover .mob-carousel-text {
  color: #ff6347; /* Change text color on hover (example) */
}


.Mob-home-icecream-singel-products-bottombackground {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin: 0 10px;
  border-radius: 30px;
  box-shadow: 0px 0px 54px #00000021;
  background: #ffffff;
  text-align: center;
  padding: 15px;
  height: 235px;
}

.Mob-home-icreamslide {
  width: 100%; /* Use relative units to allow flexibility */
  max-width: 100px;
  min-width: 100px;
  max-height: 105px;
  min-height: 105px;
  aspect-ratio: 1 / 1; /* Ensures a consistent square aspect ratio */
  border-radius: 15px;
  margin-bottom: 10px;
  box-sizing: border-box; /* Ensures padding and borders don't affect size */
}

.Mob-home-icreamslide2 {
  width: 100%;
  max-width: 100px;
max-height: 105px;
  border-radius: 15px;
}

.Mob-home-icreamslide3 {
  width: 100%;
  max-width: 100px;
max-height: 105px;  border-radius: 15px;
}

.Mob-home-icreamslide-sub-p {
  margin: 0;
}

.Mob-home-icreamslide-head-p,
.Mob-home-icreamslide-sub-p {
  text-align: center;
}
.Mob-home-icreamslide-head-p {
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-family: Fraunces;
  color: #333;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; 
  -webkit-box-orient: vertical;
  line-height: 1.2em; 
  max-height: 3.6em;
}

.Mob-home-icreamslide4 {
  width: 100%;
  max-width: 100px;
max-height: 105px;
  border-radius: 15px;
}

.Mob-home-sliderarrow {
  height: 38px;
}
.Mob-home-Icreamslider-arrow-section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
}

.Mob-home-ourstory-main-section {
  width: 100%;
  height: auto;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 30px;
}

.Mob-home-sweet-deal-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  margin-left: 20px;
}

.Mob-home-sweet-deal-text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
}

.Mob-home-sweet-deal-head {
  color: #ffffff;
  font-family: Fraunces;
  font-size: 40px;
  font-weight: bold;
  opacity: 1;
  margin-bottom: 15px;
  text-align: left;
}

.Mob-home-sweet-deal-text {
  color: #ffffff;
  font-size: 16px;
  opacity: 0.6;
  text-align: left;
  /* margin-top: 15px; */
  width: 86%;
}

.Mob-home-ourstory-head {
  color: #094344;
  font-weight: bold;
  font-size: 40px;
  margin-left: 45%;
  font-family: Fraunces 144pt SuperSoft 144pt SuperSoft NonWonky;
}

.Mob-home-Our-story-span {
  color: #094344;
  font-weight: bolder;
  font-size: 42px;
  font-family: Fraunces 144pt SuperSoft 144pt SuperSoft NonWonky;
}

.Mob-home-ourstory-container-main {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
}

.Mob-home-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
}

.Mob-home-bluediv {
  border-radius: 50%;
  width: 250px;
  height: 250px;
  background: #11989a 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Mob-home-lastdiviceimg {
  width: 88%;
  margin-left: 19%;
}

.Mob-home-milkwave-container {
  position: relative;
}

.Mob-home-milkwaveimg {
  width: 100%;
  position: relative;
  left: 1%;
  margin-bottom: 0;
}

.Mob-home-doubleline {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Mob-home-ourstory-ice-img-section-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  z-index: 1;
}

.Mob-home-ourstory-ice-img-section-container > * {
  margin-left: 50px;
}

.Mob-home-ourstory-layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* height: 100%; */
}

.Mob-home-ourstory-ice-img-section-left,
.Mob-home-ourstory-ice-img-section-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 99%;
  gap: 10px;
}


.Mob-home-ourstory-ice-img-section4 {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;

  /* margin-left: 50%; */
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background-color: #f9fde5;
  height: 100%;
  border: 3px solid #f9fde5;
}

.Mob-home-ourstory-ice-img-section1,
.Mob-home-ourstory-ice-img-section2,
.Mob-home-ourstory-ice-img-section3,
.Mob-home-ourstory-ice-img-section5,
.Mob-home-ourstory-ice-img-section6
{
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-right: 10%; */
  gap: 10px;
  border-radius: 12px;
  background-color: #f9fde5;
  height: 100%;
  border: 3px solid #f9fde5;
}



.Mob-home-firstimage {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.Mob-home-secimage {
  width: 100%;
  height: auto;
  position: absolute;
  top: 23%;
  /* left: 22%;
  
  top: 82%; */
}
.Mob-home-ourstory-ice-img-section1-p,
.Mob-home-ourstory-ice-img-section2-p,
.Mob-home-ourstory-ice-img-section3-p,
.Mob-home-ourstory-ice-img-section4-p,
.Mob-home-ourstory-ice-img-section5-p,
.Mob-home-ourstory-ice-img-section6-p {
  font-size: 14px;
  font-weight: bold;
  color: #094344;
  text-align: center;
  padding: 5px 0;
}

@media (max-width: 600px) {
  .Mob-home-ourstory-ice-img-section-left,
  .Mob-home-ourstory-ice-img-section-right {
    flex-direction: column;
    align-items: flex-start;
  }

  .Mob-home-ourstory-layout {
    padding: 15px;
  }

  .Mob-home-ourstory-ice-img-section1,
  .Mob-home-ourstory-ice-img-section2,
  .Mob-home-ourstory-ice-img-section3,
  .Mob-home-ourstory-ice-img-section4,
  .Mob-home-ourstory-ice-img-section5,
  .Mob-home-ourstory-ice-img-section6 {
    width: 100%;
  }

  .Mob-home-ourstory-mainimage {
    max-width: 100%;
    height: auto;
  }
}
@media (max-width: 768px) {
  .Mob-home-icecream-products-bottombackground {
    flex-wrap: nowrap;
    justify-content: center;
  }

  .Mob-home-icecream-singel-products-bottombackground {
    width: 40%;
    margin-bottom: 20px;
  }

  .Mob-home-ourstory-head {
    margin-left: 0;
    text-align: center;
  }

  .Mob-home-bluediv {
    width: 200px;
    height: 200px;
  }

  .Mob-home-lastdiviceimg {
    width: 80%;
  }

  .Mob-home-secimage {
    width: 20%;
    height: auto;
    /* left: 50%;
    transform: translateX(-50%); */
  }
}

.Mob-home-sweetdeal-bottombackground {
  background: #ffffff;
  padding: 20px;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.Mob-home-sweet-deal-ourstory {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../images/Mask Group 1.png");
  background-color: #11989a;
  border-radius: 30px;
  padding: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  animation: moveBackground 11s linear infinite; }

@keyframes moveBackground {
  0% {
    background-position: 0 0; 
  }
  50% {
    background-position: 100% 100%; 
  }
  100% {
    background-position: 0 0; 
  }
}
.mob-carousel-btn {
  background-color: #007BFF; /* Blue background */
  color: white; /* White text */
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.mob-carousel-btn:hover {
  background-color: #0056b3; /* Darker blue when hovered */
}

.Mob-home-ice-made-main-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 20px; */
}

.Mob-home-image-container {
  width: 100%;
  text-align: center;
}
.Mob-home-sweetdeal-image-container {
  width: 60%;
  text-align: center;
}

.Mob-home-sweetdeal-ice {
  height: auto;
  width: 34%;
  max-width: 200px;
  border-radius: 10px;
}

.Mob-home-text-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.Mob-home-sweetdeal-icecream-text-head {
  font-size: 26px;
  color: #ffffff;
  font-weight: bold;
  font-family: "Fraunces 144pt SuperSoft 144pt SuperSoft NonWonky";
}

.Mob-home-sweetdeal-icecream-text-p {
  font-size: 19px;
  font-weight: medium;
  color: #ffffff;
  opacity: 0.6;
}

.Mob-home-discoverbuttonhomepage {
  width: 160px;
  height: 42px;
  background: #ceee36;
  border-radius: 153px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  margin: 0 auto;
}
.Mob-home-discover-button {
  width: 160px;
  height: 42px;
  background: #ceee36;
  border-radius: 153px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  margin: 0 auto;
}
.mob-dine-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.Mob-home-ourstory-mainimage{
  height: 92px;
  width: 90px;
  border-radius: 50%;
}
.mob-dine-popup {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 90%;
  max-width: 800px;
  height: 45%;
  display: flex;
  flex-direction: column;
}

.mob-dine-popup-header {
  margin: 0 auto 30px auto;
  font-size: 22px;
  font-family: Fraunces;
  font-weight: 700;
  color: #094344;
  width: 90%;
  text-align: center;
}

.mob-dine-popup-options {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 30px;
}

.mob-dine-popup-button {
  padding: 10px 20px;
  background: #fff;
  color: #000;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  font-size: 18px;
  font-family: Fraunces;
  font-weight: 700;
  border: 1px solid #ceee36;
  margin-top: 15px;
}

.mob-dine-popup-button:hover {
  background: #ceee36;
}
.category{
  font-family: 'Fraunces 144pt SuperSoft 144pt SuperSoft NonWonky', sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: #094344;
}
.mob-dine-popup-close {
  background: transparent;
  border: none;
  color: #555;
  cursor: pointer;
  font-size: 16px;
}
@media only screen and (min-width: 600px) and (max-width: 768px) {
  .Mob-home-icecream-text-head {
    font-size: 28px;
  }
  .Mob-home-icecream-text-passion {
    font-size: 38px;
  }
  .Mob-home-icecream-singel-products-bottombackground {
    width: 45%;
  }
}

@media only screen and (min-width: 400px) and (max-width: 599px) {
  .Mob-home-icecream-text-head {
    font-size: 25px;
  }
  .Mob-home-icecream-text-passion {
    font-size: 35px;
  }
  .Mob-home-icecream-singel-products-bottombackground {
    width: 45%;
  }
}

@media only screen and (max-width: 399px) {
  .Mob-home-icecream-text-head {
    font-size: 20px;
  }
  .Mob-home-icecream-text-passion {
    font-size: 28px;
  }
  .Mob-home-icecream-singel-products-bottombackground {
    width: 100%;
  }
}

