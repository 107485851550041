.mob-career-full-width-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: linear-gradient(#f9fde500, #f9fde5, #f9fde500);
    justify-content: space-between;
  }
  .mob-car-toggle-button {
    background-color: transparent;
    border: none;
    color: #11989a;
    cursor: pointer;
    font-size: 1rem;
    font-family: "Fraunces", serif;
    margin-left: 10px; 
    display: inline; 
  }
  
  .mob-car-toggle-button:hover {
    text-decoration: underline;
  }
  .pop-mobcar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .pop-mobcar-popup {
    background-color: #f9fde5;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
  }
  
  .pop-mobcar-label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
    display: block;
  }
  
  .pop-mobcar-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .pop-mobcar-submit-btn {
    background-color: #ceee36;
    color: #000;
    border: none;
    font-family: Fraunces;
    font-weight: 700;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;

  }
  .mob-career-image{
    max-width: 300px;
    max-height: 200px;
  }
  .pop-mobcar-button-sect{
    display: flex;
    justify-content: space-between;
    width: 90%;
  }
  .pop-mobcar-submit-btn:hover {
    background-color: #0056b3;
  }
  
  .pop-mobcar-close {
    background-color: #f44336;
    color: white;
    font-family: Fraunces;
    font-weight: 700;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    /* margin-top: 15px; */
  }
  
  .pop-mobcar-recaptcha {
    margin-top: 15px;
  }
   .pop-mobcar-popup-head{
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #094344;
    font-family: Fraunces; 
   }
  .mob-career-navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .mob-career-back-button {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  
  .mob-career-page-title {
    font-size: 20px;
    font-weight: bold;
    font-family: Fraunces;
    color: #094344;
  }
  
  .mob-career-logo {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  
  .mob-career-popup-menu {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    width: 75%;
    height: 100%;
    background-color: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
    padding: 20px;
    z-index: 2000;
  }
  
  .mob-career-popup-menu.show {
    display: block;
  }
  
  .mob-career-pop-menu-navbar-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .mob-career-pop-menu-navbar-img {
    width: 12%;
    height: 10%;
  }
  
  .mob-career-pop-menu-navbar-main li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    font-size: 18px;
    cursor: pointer;
    font-family: Fraunces;
    color: #094344;
    width: 60%;
  }
  
  .mob-career-close-popup {
    display: block;
    margin-top: 20px;
    padding: 10px;
    background-color: #ceee36;
    border: none;
    border-radius: 20px;
    font-family: Fraunces;
    font-weight: 700;
    color: #000;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
  }
  
  .mob-career-content {
    padding: 20px;
    text-align: center;
    margin-top: 60px;
    flex-grow: 1;
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 10px;
    font-family: inter;
  }
  
  .mob-career-header {
    font-size: 1.5rem;
    color: #11989a;
    margin-bottom: 10px;
    font-family: Fraunces;
  }
  
  .mob-career-text {
    font-size: 16px;
    color: #000000;
    margin: 20px 0;
    font-family: inter;

  }
  
  .mob-career-apply-button {
    background-color: #ceee36;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #000;
    cursor: pointer;
  }
  
  .mob-career-image-section {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  
  .mob-career-image {
    width: auto;
    max-width: 400px;
    height: auto;
    max-height: 250px;
    border-radius: 10px;
  }
  
  .mob-career-footer {
    width: 100%;
    padding: 0;
    margin-top: auto;
    background-color: #fff;
  }
  