.mainfooter {
  width: 100%;
  background: linear-gradient(to bottom, #f9fde500, #f9fde5);
  padding: 10px;
  position: relative;
}

.sideborder,
.downborder,
.leftborder,
.leftsideborder {
  position: absolute;
  z-index: 1;
  pointer-events: none;
}

.sideborder {
  bottom: 0;
  left: 0;
}

.downborder {
  bottom: 0;
  left: 0;
}

.leftborder {
  bottom: 0;
  right: 0;
}

.leftsideborder {
  bottom: 4;
  right: 0;
}

.footersub-sectionmain {
  display: flex;
  justify-content: center;
  padding: 0px 0 40px 0px;
  gap: 26%;
  width: 98%;
}
.footer-right-section {
    position: relative; /* Ensure z-index works */
    z-index: 2; /* Set a higher z-index to bring the quick links above the images */
    align-items: flex-end;
    justify-content: flex-end;
    text-align: right;
    padding: 2px 0px;
  }

.footer-social-icons {
  display: flex;
  justify-content: center;
  gap: 10%;
  height: auto;
  width: 100%;
}

.footer-center-section {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.footmainlogo {
  width: 100%;
  height: auto;
}

.footer-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 3px;
}

.footer-left-section {
  padding: 3px;
}

.footer-copyright {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #75807b;
  font-size: 14px;
  font-family: Roboto;
  padding-top: 10px;
}

.footer-copyright hr {
  width: 80%;
  margin-bottom: 10px;
  border-color: #eeeeee;
}

.footer-left-sectionp {
  color: #75807b;
  font-size: 20px;
  font-family: Poppins;
  height: 88px;
  width: 146px;
}

.footer-right-sectionp {
  color: #75807b;
  font-size: 18px;
  font-family: Poppins;
  margin-top: 5px;
  cursor: pointer; 
  text-align: right;

  z-index: 3;

}

.footer-right-sectionhead {
  font-family: Fraunces, Black;
  color: #094344;
  font-size: 20px;
  font-weight: bold;
}

.footer-center-contacttext {
  font-family: Fraunces, Black;
  font-size: 20px;
  font-weight: bold;
  color: #094344;
  text-transform: uppercase;
  opacity: 1;
}

.footer-center-contactnumber {
  font-family: Fraunces, Bold;
  font-size: 30px;
  font-weight: bold;
  color: #11989a;
  letter-spacing: 0px;
  opacity: 1;
}

.footer-centersec-contactnumber {
  font-family: Poppins;
  font-size: 22px;
  color: #303b36;
  letter-spacing: 0px;
}

.footer-center-contactcontent {
  font-family: Roboto;
  font-size: 16px;
  color: #75807b;
}

@media (max-width: 768px) {
  .footersub-sectionmain {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .footer-left-section,
  .footer-center-section,
  .footer-right-section {
    text-align: center;
    margin: 10px 0;
    width: 100%;
  }

  .footer-social-icons {
    justify-content: center;
  }

  .footer-left-sectionp,
  .footer-right-sectionp {
    font-size: 16px;
  }

  .footer-center-contacttext,
  .footer-center-contactnumber,
  .footer-centersec-contactnumber,
  .footer-center-contactcontent {
    text-align: center;
  }

  .footer-center-contactnumber {
    font-size: 24px;
  }

  .footer-centersec-contactnumber {
    font-size: 18px;
  }
}
.social-foot-icon {
  position: relative;
  display: inline-block;
  transition: transform 0.5s ease;
}

.social-foot-icon:hover {
  transform: scale(1.5);
}

.social-foot-icon::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  border: 2px solid #11989a; 
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.3s ease, height 0.3s ease;
}

.social-foot-icon:hover::after {
  width: 120%; 
  height: 120%; 
}
.footer-right-section {
  display: flex;
  gap: 20px; /* Space between the two sections */
  align-items: flex-start;
  text-align: left;
}

.footer-links-section {
  display: flex;
  flex-direction: column;
}

.footer-links-heading {
  font-family: Fraunces, Black;
  color: #094344;
  font-size: 20px;
  font-weight: bold;
}

.footer-first-sectionp {
  color: #75807b;
  font-size: 18px;
  font-family: Poppins;
  margin-top: 5px;
  text-align: left;

  cursor: pointer;
}
