/* Styles for all devices except mobile phones */
.header {
  background: #11989a 0% 0% no-repeat padding-box;
  border-radius: 0 0 86px 86px;
  width: 100%;
  padding: 10px;
  position: relative;
  background-image: url("../../../images/banner-line.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.navbar1 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 10%;
  padding: 0;
}

.navbar2 {
  display: flex;
  justify-content: space-around;
  gap: 30%;
  align-items: center;
  padding: 0;
}

.need-help {
  color: #ceee36;
  margin-right: 2rem;
}

.top-logo {
  margin-right: 10rem;
}

.bulk-order-button {
  background-color: transparent;
  border: 2px solid #ceee36;
  border-radius: 153px;
  color: #ceee36;
  cursor: pointer;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  font-weight: bold;
}

.top-user-logo {
  width: 16px;
}

.nav-links {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.nav-link {
  font: normal normal bold 16px/20px
    "Fraunces 144pt SuperSoft 144pt SuperSoft NonWonky";
  letter-spacing: 0;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
  margin-right: 2rem;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  gap: 20px;
}

.header-text {
  padding: 0px 30px;
  transform: translateX(30px);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.main-text {
  width: 402px;
  font: normal normal bold 40px/49px
    "Fraunces 144pt SuperSoft 144pt SuperSoft NonWonky";
  letter-spacing: 0;
  color: #ffffff;
  opacity: 1;
  font-size: xx-large;
  margin-top: 20px;
  font-weight: 600;
}

.sub-text {
  width: 74%;
  height: auto;
  font: normal normal medium 16px/25px Poppins;
  letter-spacing: 0;
  color: #ffffff;
  opacity: 0.6;
  gap: 20px;
}

.nav-inline-link {
  width: 3px;
  height: 3px;
  background: #ceee36 0% 0% no-repeat padding-box;
  opacity: 1;
}

.nav-sub-link {
  display: flex;
  width: 31px;
  height: 3px;
  background: #ceee36 0% 0% no-repeat padding-box;
  border-radius: 257px;
  opacity: 1;
}

.discover-button {
  width: 166px;
  height: 42px;
  cursor: pointer;
  background: #ceee36 0% 0% no-repeat padding-box;
  border-radius: 153px;
  opacity: 1;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.ice-cream-image {
  max-width: 27%;
  height: auto;
  position: absolute;
  top: 35%;
  right: 15%;
}
.bulk-order-popup {
  position: absolute;
  top: 60px;
  
  right: 10px;
  /* background-color: #ffffff; */
  border: 1px solid #ceee36;
  border-radius: 10px;
  padding: 10px;
  width: 150px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.popup-option {
  padding: 10px;
  cursor: pointer;
  text-align: center;
  background-color: #11989a;
  color: white;
  border-radius: 5px;
  margin: 5px 0;
  transition: background-color 0.3s ease;
}

.popup-option:hover {
  background-color: #ceee36;
}

@media (min-width: 769px) and (max-width: 1024px) {
  .navbar1 {
    gap: 5%;
    padding: 0 10px;
  }

  .navbar2 {
    gap: 20%;
  }

  .top-logo {
    margin-right: 5rem;
  }

  .header-content {
    gap: 10px;
    padding: 0 15px;
  }

  .header-text {
    transform: translateX(20px);
  }

  .main-text {
    width: 350px;
    font-size: 35px;
  }

  .sub-text {
    width: 65%;
    font-size: 15px;
  }

  .ice-cream-image {
    max-width: 30%;
    top: 38%;
    right: 10%;
  }
}

@media (min-width: 1025px) {
  .navbar1 {
    gap: 10%;
    padding: 0 20px;
  }

  .navbar2 {
    gap: 25%;
  }

  .top-logo {
    margin-right: 8rem;
  }

  .header-content {
    gap: 25px;
    padding: 0 40px;
  }

  .main-text {
    font-size: 45px;
    width: 450px;
  }

  .sub-text {
    width: 70%;
    font-size: 18px;
  }

  .ice-cream-image {
    max-width: 25%;
    top: 35%;
    right: 10%;
  }
}

.strawberry-image {
  position: absolute;
  top: 20%;  /* You can adjust this as per your layout */
  left: 90%; /* Center horizontally */
  transform: translateX(-50%); /* Center horizontally */
  width: 50px; /* Adjust size as needed */
  z-index: 10; /* Ensure it is above other content */
}
