.guest-cart-full-width-container {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.guest-cart-navbar {
  display: flex;
  align-items: center;
  gap: 30px;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  padding: 10px 15px 10px 30px;
  font-size: 20px;
  box-shadow: 0 3px 0px rgba(0, 0, 0, 0.08235294117647059);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.guest-cart-sidemainlogo {
  position: fixed;
  z-index: 1000;
  width: 7%;
  height: 11%;
}

.guest-cart-back-button {
  width: 24px;
  height: 24px;
  margin-left: 14%;
  cursor: pointer;
}

.guest-cart-page-title {
  font-family: Fraunces;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 0;
  color: #094344;
  text-transform: uppercase;
}

.guest-cart-item-container {
  background: linear-gradient(#f9fde500, #f9fde500, #f9fde5);
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  overflow-y: auto;
  margin-top: 60px;
}

.guest-cart-secondbox {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  margin: 15px 0;
  width: 90%;
  max-width: 600px;
  border: 2px solid #eee;
}

.guest-cart-thirdbox {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  margin: 15px 0;
  width: 90%;
  max-width: 600px;
  border: 2px solid #eee;
}

.guest-cart-item-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  margin-top: 20px;
  padding: 10px;
  text-align: left;
}

.guest-cart-whatsappicon {
  width: 5%;
}

.guest-cart-whatsapp-text {
  font-size: 16px;
  font-weight: 500;
  font-family: inter;
}

.guest-cart-grey-line {
  border: none;
  height: 1px;
  background-color: #eee;
  margin-top: 20px;
  width: 100%;
}

.guest-cart-items-in-cart {
  font-size: 18px;
  font-weight: 800;
  margin-top: 20px;
  font-family: Fraunces;
  letter-spacing: 0;
  color: #094344;
  text-transform: uppercase;
}

.guest-cart-allitemsdiv {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.guest-cart-product-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 10px;
}

.guest-cart-product-details-name {
  font-size: 16px;
  font-weight: 500;
  font-family: inter;
  color: #094344;
}

.guest-cart-item-description {
  margin-top: 5px;
  font-size: 16px;
  color: #666;
  font-family: inter;
}

.guest-cart-item-price {
  font-size: 18px;
  color: #333;
  font-weight: 500;
  font-family: inter;
  margin-top: 10px;
}

.guest-cart-quantity-control {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.guest-cart-quantity-button {
  background-color: #ceee36;
  color: #000;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
  margin-right: 5px;
}

.guest-cart-quantity-display {
  font-size: 16px;
  margin: 0 10px;
}

.guest-cart-preparation-instructions {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.guest-cart-delete-button {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
  border-radius: 15px;
  width: 95px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  font-family: Fraunces;
}

.guest-cart-preparation-button {
  background-color: #fff;
  color: #094344;
  font-family: inter;
  font-weight: 400;
  border: none;
  border-bottom: 1px solid #eeee;
  padding: 10px 15px;
  cursor: pointer;
}

.guest-cart-billdetatilstext {
  font-size: 24px;
  margin-top: 20px;
  padding: 10px;
  text-align: left;
  font-family: Fraunces;
  font-weight: bold;
  letter-spacing: 0;
  color: #094344;
}

.guest-cart-thirdbox-item-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  margin-top: 20px;
  font-weight: 500;
  font-family: inter;
  padding: 5px 10px;
  text-align: left;
}

.guest-cart-topaytext {
  font-size: 18px;
  margin-top: 20px;
  padding: 10px;
  text-align: left;
  font-family: Fraunces;
  font-weight: bold;
  letter-spacing: 0;
  color: #094344;
}

.guest-cart-addaddressandpaybutton {
  display: inline-block;
  padding: 12px 20px;
  margin: 10px 0;
  font-size: 19px;
  font-weight: 700;
  font-family: Fraunces;
  color: #000;
  background-color: #ceee36;
  border: none;
  width: 100%;
  margin-top: 10%;
  border-radius: 20px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

/* Popup styles */
.guest-cart-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.guest-cart-popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 400px;
  text-align: center;
}

.guest-cart-popup-pre-head {
  font-weight: bold;
  font-size: 18px;
  color: #094344;
  font-family: Fraunces;
}

.guest-cart-popup-textarea {
  width: 100%;
  height: 100px;
  margin: 10px 0;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.guest-cart-popup-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.guest-cart-popup-button {
  background-color: #ceee36;
  color: #000;
  border: none;
  border-radius: 24px;
  font-weight: 700;
  font-family: Fraunces;
  padding: 10px 15px;
  cursor: pointer;
  flex: 1;
}

.guest-cart-popup-button.guest-cart-skip-button {
  background-color: #ccc;
}

.guest-cart-popup-button.guest-cart-add-button {
  background-color: #ceee36;
}

/* Media Query for larger screens */
@media (min-width: 768px) {
  .guest-cart-item-container {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
  }

  .guest-cart-secondbox {
    width: 90%;
    margin: 15px;
  }

  .guest-cart-thirdbox {
    width: 28%;
    margin: 15px;
  }
}