.bottombackground {
  background: #f9fde5;
  padding: 0px 20px;
  position: relative;
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 75vh;
}

.coupon-bottombackgroundcoupon {
  background: #11989a;
  padding: 0px 20px;
  position: relative;
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center vertically */
  height: 50vh;
}

.coupon-photo-container {
  display: flex;
  justify-content: space-evenly; /* Evenly spaces the images with equal gaps */
  align-items: center;
  gap: 60px; /* Increased gap between photos */
  width: 100%;
  max-width: 1200px; /* Adjust the max-width to your layout needs */
}

.coupon-photo {
  width: 22%; /* Adjust this based on how large you want the images */
  height: auto;
  transition: transform 0.3s ease, opacity 0.3s ease;
  object-fit: cover;
}

.coupon-photo:hover {
  transform: scale(1.1); /* Scales the image when hovered */
  opacity: 0.9; /* Slight fade effect on hover */
}


.topwave {
  position: relative;
  top: 5px;
  left: 0;
  width: 100%;
  height: auto;
  z-index: -1;
}

.bottombackground-head-text {
  color: #094344;
  font-family: "Fraunces 144pt SuperSoft 144pt SuperSoft NonWonky", sans-serif;
  font-size: 40px;
  font-weight: bold;
  opacity: 1;
  margin-top: 10px;
}

.bottombackground-head-scoope {
  color: #094344;
  font-size: 16px;
  font-family: Poppins;
  opacity: 0.6;
  margin: 10px 0;
}

.products-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin: 16px 0 35px 0px ;
}
.icecreams {
  width: 166px;
  height: 42px;
  background: #ceee36;
  border-radius: 153px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Fraunces 144pt SuperSoft 144pt SuperSoft NonWonky", sans-serif;
  text-align: center;
  letter-spacing: 0px;
  color: #094344;
  text-transform: uppercase;
  opacity: 1;
}

.secondimage {
  margin-right: 95%;
  width: 30%;
  height: 30%;
  opacity: 1;
}

.ice-made-main-section {
  display: flex;
  padding: 20px 30px;
}

.discoverbuttonhomepage {
  width: 166px;
  height: 42px;
  background: #ceee36;
  border-radius: 153px;
  opacity: 1;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.discoverbuttonsecondice {
  width: 166px;
  height: 42px;
  margin-bottom: 10%;
  background: #ceee36;
  border-radius: 153px;
  opacity: 1;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.discover-button-onice {
  width: 166px;
  height: 42px;
  background: #ceee36;
  border-radius: 153px;
  opacity: 1;
  display: flex;
  color: #094344;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 100%;
  font-family: "Fraunces 144pt SuperSoft 144pt SuperSoft NonWonky", sans-serif;
  text-align: left;
  left: 17%;
  padding: 10px 0px;
  font-weight: bold;
  cursor: pointer;
}

.ice-made-img-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 30px;
  overflow: hidden;
}

.image-container-bluediv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 30px;
}
.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-right: 30px; */
}
.thirdimage {
  position: absolute;
  top: 70%;
  left: 58%;
  transform: translateX(2%);
  z-index: 0;
  width: 40%;
  height: auto;
}

.text-content {
  position: relative;
  z-index: 3;
}
@keyframes roundAndPunch {
  0% {
    transform: rotate(0deg) scale(1);
  }
  25% {
    transform: rotate(90deg) scale(1.1);
  }
  50% {
    transform: rotate(180deg) scale(1);
  }
  75% {
    transform: rotate(270deg) scale(1.1);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}

.logoimage {
  animation: roundAndPunch 10s ease-in-out infinite;
}

.logoimage {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 78%;
  right: 11%;
}

.icecream-text-head {
  font-weight: 700;
  font-size: 40px;
  color: #094344;
  font-family: "Fraunces 144pt SuperSoft 144pt SuperSoft NonWonky";
  margin-bottom: 20px;
}

.icecream-text-passion {
  display: block;
  font-weight: 700;
  font-size: 51px;
  color: #094344;
  font-family: "Fraunces 144pt SuperSoft 144pt SuperSoft NonWonky";
  margin-top: 10px;
}

.icecream-text-p {
  font-family: "Poppins", sans-serif;
  font-weight: medium;
  color: #000000;
  opacity: 0.6;
  font-size: 16px;
  margin-bottom: 20px;
}

.icecream-products-slider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.icecream-products-bottombackground {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  
  gap: 20px;
}

.icecream-singel-products-bottombackground {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  margin: 0 10px;
  border-radius: 30px;
  box-shadow: 0px 0px 54px #00000021;
  background: #ffffff;
  text-align: center;
  padding: 15px;
  height: 250px;
}

.icreamslide {
  min-width: 200px;
  max-width: 200px;
  /* height: 100%; */
  min-height: 130px;
  max-height: 130px;
  height: auto;
  border-radius: 15px;
  margin-bottom: 10px;
}

.icreamslide2 {
  width: 100px;
  max-height: 120px;
  height: auto;
  border-radius: 15px;
}

.icreamslide3 {
  width: 100px;
  height: auto;
  max-height: 120px;
  border-radius: 15px;
}

.icreamslide-sub-p {
  margin: 0;
}
.icreamslide-head-products,
.icreamslide-sub-p {
  text-align: center;
}

.icreamslide-head-products {
  font-family: Fraunces;
  font-size: 18px;
  font-weight: bold;
  opacity: 1;
  width: 300px;
  color: #094344;
}
.icreamslide4 {
  width: 100px;
  max-height: 120px;
  height: auto;
  border-radius: 15px;
}

.sliderarrow {
  height: 38px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.sliderarrow.left-arrow {
  left: 10px;
}

.sliderarrow.right-arrow {
  right: 10px;
}
.Icreamslider-arrow-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.ourstory-main-section {
  width: 100%;
  height: 52vh;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 30px;
}

.sweet-deal-ourstory {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 83%;
  background-color: #11989a;
  background-image: url("../../src/images/Mask Group 1.png");
  background-size: cover;
  background-position: center;
  border-radius: 30px;
  height: 155px;
  padding: 0px 20px 20px 20px;
  animation: moveBackground 5s infinite alternate ease-in-out;
}

@keyframes moveBackground {
  0% {
    background-position: center top;
  }
  50% {
    background-position: center bottom;
  }
  100% {
    background-position: center top;
  }
}

.sweet-deal-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  margin-left: 20px;
}

.sweet-deal-text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
}

.sweet-deal-head {
  color: #ffffff;
  font-family: Fraunces;
  font-size: 40px;
  font-weight: bold;
  opacity: 1;
  margin-bottom: 15px;
  text-align: left;
}

.sweet-deal-text {
  color: #ffffff;
  font-size: 16px;
  opacity: 0.6;
  text-align: left;
  /* margin-top: 15px; */
  width: 86%;
}

.sweetdeal-ice {
  height: 175px;
  width: auto;
  margin-right: 20px;
}

.ourstory-head {
  color: #094344;
  font-weight: bold;
  font-size: 40px;
  margin-left: 45%;
  font-family: "Fraunces 144pt SuperSoft 144pt SuperSoft NonWonky", sans-serif;
}

.Our-story-span {
  color: #094344;
  font-weight: bolder;
  font-size: 42px;
  font-family: "Fraunces 144pt SuperSoft 144pt SuperSoft NonWonky", sans-serif;
}

.ourstory-container-main {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
}

.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
}

.bluediv {
  border-radius: 50%;
  width: 250px;
  height: 250px;
  background: #11989a 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lastdiviceimg {
  width: 88%;
  margin-left: 11%;
}

.milkwave-container {
  position: relative;
}

.milkwaveimg {
  width: 100%;
  position: relative;
  left: 1%;
  margin-bottom: 0;
}

.doubleline {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ourstory-ice-img-section-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  z-index: 1;
}

.ourstory-ice-img-section-container > * {
  margin-left: 50px;
}

.ourstory-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 35px;
  height: 76vh;
}

.ourstory-ice-img-section-left,
.ourstory-ice-img-section-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.ourstory-ice-img-section6 {
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  margin-right: 30%;
  gap: 10px;
}
.ourstory-ice-img-section5 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 12%;
  gap: 15px;
}
.ourstory-ice-img-section4 {
  display: flex;
  margin-bottom: 20px;
  margin-left: 60%;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.ourstory-ice-img-section1 {
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  margin-right: 30%;
  gap: 10px;
}

.ourstory-ice-img-section2 {
  display: flex;
  margin-bottom: 20px;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-left: 20%;
}

.ourstory-ice-img-section3 {
  display: flex;
  margin-bottom: 20px;
  margin-left: 60%;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.ourstory-ice-img-section1-p,
.ourstory-ice-img-section2-p,
.ourstory-ice-img-section3-p,
.ourstory-ice-img-section4-p,
.ourstory-ice-img-section5-p,
.ourstory-ice-img-section6-p {
  max-width: 150px;
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  text-align: center;
}
.firstimage {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.secimage {
  width: 13%;
  height: 28%;
  position: absolute;
  left: 22%;
  top: 82%;
}

.ourstory-ice-img-section1-p {
  font-family: "Fraunces 144pt SuperSoft 144pt SuperSoft NonWonky", sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: #094344;
  width: 95px;
}

.ourstory-ice-img-section2-p {
  font-family: "Fraunces 144pt SuperSoft 144pt SuperSoft NonWonky", sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: #094344;
  width: 190px;
}

.ourstory-ice-img-section3-p {
  font-family: "Fraunces 144pt SuperSoft 144pt SuperSoft NonWonky", sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: #094344;
  width: 200px;
}
.carousel-section {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}

.carousel-image {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
  border-radius: 15px;
  cursor: pointer; /* Show pointer cursor when hovered */
}

.carousel-item {
  position: relative; /* Need to position text over the image */
}

.carousel-text {
  position: absolute;
  top: 70%; /* Center the text vertically */
  left: 50%; /* Center the text horizontally */
  transform: translate(-50%, -50%); /* Adjust to center perfectly */
  color: white;
  font-size: 30px;
  font-family: "Fraunces 144pt SuperSoft 144pt SuperSoft NonWonky", sans-serif;
  font-weight: bold;
  text-align: center;
  text-shadow: 0 4px 6px rgba(0, 0, 0, 0.6); /* Shadow effect */
  opacity: 0; /* Initially hide the text */
  transition: opacity 0.3s ease-in-out; /* Smooth transition */
}

.carousel-item:hover .carousel-text {
  opacity: 1; /* Make the text visible on hover */
}

.Home-carousel {
  margin-top: 2%;
  padding: 30px;
}

.dinein-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.dinein-popup {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 80%;
  max-width: 800px;
  height: 45%;
  display: flex;
  flex-direction: column;
}

.dinein-popup-header {
  margin: 0 auto 20px auto;
  font-size: 22px;
  font-family: Fraunces;
  font-weight: 700;
  margin-bottom: 30px;
  color: #094344;
  width: 40%;
  text-align: center;
}

.dinein-popup-options {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 20px;
}

.dinein-popup-button {
  padding: 10px 20px;
  background: #fff;
  color: #000;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  font-size: 18px;
  font-family: Fraunces;
  font-weight: 700;
  border: 1px solid #ceee36;
  margin-top: 15px;
  transition: background 0.3s;
}

.dinein-popup-button:hover {
  background: #ceee36;
}

.dinein-popup-close {
  background: transparent;
  border: none;
  color: #555;
  cursor: pointer;
  font-size: 16px;
}

.ourstory-ice-img-section-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

/* 
@media (max-width: 1440px) {
  .thirdimage {
    left: 55%;
  }
}

@media (max-width: 1280px) {
  .thirdimage {
    left: 52%;
  }

  .logoimage {
    right: 8%;
  }
}

@media (max-width: 1024px) {
  .ice-made-main-section {
    flex-direction: column;
    align-items: center;
  }

  .ice-made-img-section {
    flex-direction: column;
    align-items: center;
  }

  .image-container {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .thirdimage {
    position: static;
    transform: none;
    width: 80%;
    margin-top: 30px;
  }

  .logoimage {
    position: static;
    margin-top: 20px;
    margin-right: 0;
  }

  .ourstory-layout {
    flex-direction: column;
    height: auto;
  }

  .ourstory-ice-img-section-left,
  .ourstory-ice-img-section-right {
    width: 100%;
  }

  .ourstory-ice-img-section3 {
    margin-left: 0;
  }

  .sweet-deal-ourstory {
    flex-direction: column;
    height: auto;
    padding: 20px;
  }

  .sweet-deal-content {
    margin-left: 0;
    margin-top: 20px;
  }

  .sweetdeal-ice {
    width: 20%;
  }
}

@media (max-width: 768px) {
  .icecream-products-bottombackground {
    flex-wrap: wrap;
    justify-content: center;
  }

  .icecream-singel-products-bottombackground {
    width: 40%;
    margin-bottom: 20px;
  }

  .ourstory-head {
    margin-left: 0;
    text-align: center;
  }

  .bluediv {
    width: 200px;
    height: 200px;
  }

  .lastdiviceimg {
    width: 80%;
  }

  .secimage {
    width: 20%;
    height: auto;
    left: 50%;
    transform: translateX(-50%);
  }
} */
