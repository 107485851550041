.mob-policy-full-width-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: linear-gradient(#f9fde500, #f9fde5,#f9fde5, #f9fde500);
    justify-content: space-between;
}
.mob-policy-toggle-button {
    background-color: transparent;
    border: none;
    color: #11989a;
    cursor: pointer;
    font-size: 1rem;
    font-family: "Fraunces", serif;
    margin-left: 10px;
    display: inline;
}

.mob-policy-navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.mob-policy-back-button {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.mob-policy-page-title {
    font-size: 20px;
    font-weight: bold;
    font-family: Fraunces;
    color: #094344;
}

.mob-policy-logo {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.mob-policy-popup-menu {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    width: 75%;
    height: 100%;
    background-color: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
    padding: 20px;
    z-index: 2000;
}

.mob-policy-popup-menu.show {
    display: block;
}

.mob-policy-pop-menu-navbar-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.mob-policy-pop-menu-navbar-img {
    width: 12%;
    height: 10%;
}

.mob-policy-pop-menu-navbar-main li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    font-size: 18px;
    cursor: pointer;
    font-family: Fraunces;
    color: #094344;
    width: 60%;
}

.mob-policy-close-popup {
    display: block;
    margin-top: 20px;
    padding: 10px;
    background-color: #ceee36;
    border: none;
    border-radius: 20px;
    font-family: Fraunces;
    font-weight: 700;
    color: #000;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
}

.mob-policy-content {
    padding: 20px;
    text-align: center;
    margin-top: 60px;
    flex-grow: 1;
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 10px;
    font-family: inter;
}

.mob-policy-header {
    font-size: 1.5rem;
    color: #11989a;
    margin-bottom: 10px;
    font-family: Fraunces;
}

.mob-policy-subhead {
    font-size: 1.2rem;
    color: #11989a;
    margin-bottom: 10px;
    font-family: inter;
}

.mob-policy-text {
    font-size: 16px;
    color: #000000;
    margin: 20px 0;
    font-family: inter;
}

.mob-policy-footer {
    width: 100%;
    padding: 0;
    margin-top: auto;
    background-color: #fff;
}
