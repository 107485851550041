.personal-detail-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.personal-detail-head {
  background: #11989a 0% 0% no-repeat padding-box;
  width: 100%;
  padding: 10px;
  height: 14vh;
  border-radius: 0 0 65px 65px;
  position: relative;
  background-image: url("../../images/banner-line.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.personal-detail-steps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
  position: relative;
  padding: 20px;
}
.personal-detail-content-head{
  font-family: Fraunces;
    font-weight: 700;
    color: #094344;
}
.personal-detail-step {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #11989a;
  border-radius: 50%;
  font-size: 18px;
  font-weight: bold;
  background-color: #11989a;
  color: #fff;
}

.personal-detail-line {
  position: absolute;
  height: 2px;
  background-color: #11989a;
  z-index: -1;
}

.personal-detail-line-1 {
  width: 46%;
  left: 5%;
  transform: translateY(-50%);
}

.personal-detail-animated-line {
  position: absolute;
  height: 2px;
  background-color: #11989a;
  z-index: -1;
  left: 50%;
  transform: translateY(-50%);
  animation: expandLine 7s linear forwards;
}

@keyframes expandLine {
  0% {
    width: 0%;
  }
  100% {
    width: 47%;
  }
}

.personal-detail-step-1 {
  left: 0;
}

.personal-detail-step-2 {
  left: 50%;
  transform: translateX(-50%);
}

.personal-detail-step-3 {
  right: 0;
}

.personal-detail-ice-van {
  width: 25px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: moveVan 6s linear forwards;
}

@keyframes moveVan {
  0% {
    left: 50%;
  }
  100% {
    left: 100%;
  }
}

.personal-detail-content {
  width: 58%;
  padding: 5% 0%;
}

.personal-detail-fields-container {
  display: flex;
  flex-wrap: wrap;
  gap: 4%;
  margin-top: 5%;
  width: 100%;
}

.personal-detail-field {
  flex: 0 0 48%;
  margin-bottom: 20px;
}

.personal-detail-field p {
  margin-bottom: 5px;
  font-family: Fraunces;
  font-weight: 700;
  color: #094344;
}

.personal-detail-field input {
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #eee;
}

.personal-detail-field input:focus {
  border-bottom: 1px solid #11989a;
  outline: none;
}

.personal-detail-fields-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.finish-sign-up-button {
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bold;
  color: rgb(0, 0, 0);
  background-color: #ceee36;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-weight: bold;
  font-family: Fraunces;
  transition: background-color 0.3s ease;
}

.finish-sign-up-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.finish-sign-up-button:hover:enabled {
  background-color: #bada29;
}

@media (max-width: 768px) {
  .personal-detail-steps {
    width: 80%;
  }

  .personal-detail-fields-container {
    flex-direction: column;
    align-items: center;
  }

  .personal-detail-field {
    width: 100%;
  }
}
