.mob-about-page {
    background-color: #f9fde5;
  }
  .mob-about-content-section {
    display: flex;                    
    flex-direction: column;            
    align-items: center;               
    justify-content: center;           
    padding: 20px;                    
    margin: 0 auto;                   
    max-width: 800px;                 
    background-color: #f9fde5;       
}

.mob-about-content-item {
    text-align: center;               
    margin: 15px 0;                  
    padding: 10px;                    
    background-color: #fff;           
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;              
    width: 100%;                      
    max-width: 600px;                
  }
  
 .mob-about-content-head {
    font-size: 1.5rem;
  color: #11989a;
  margin-bottom: 10px;
  font-family: Fraunces;
}
.mob-about-content-text{
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 10px;
  font-family: inter;
}
  .mob-about-navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .mob-about-back-button,
  .mob-about-menu-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  
  .mob-about-title {
    font-family: Fraunces;
    color: #094344;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0;
    text-transform: uppercase;
    opacity: 1;
  }
  
  .mob-about-hero-section {
    background-color: #f9fde5;
    padding: 40px 20px;
    text-align: center;
margin-top: 30px;
    color: #000;
  }
  
  .mob-about-hero-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .mob-about-hero-content h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    font-family: Fraunces;
    color: #094344;

  }
  
  .mob-about-hero-content p {
    font-size: 1.2rem;
    font-family: inter;
    font-weight: 400;
  }
  
  .mob-about-story-section {
    padding: 40px 20px;
    background-color: #fff;
  }
  
  .mob-about-story-content {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }
  
  .mob-about-story-content h2 {
    font-size: 1.5rem;
    color: #11989a;
    margin-bottom: 10px;
    font-family: Fraunces;
  }
  
  .mob-about-story-content p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 10px;
    font-family: inter;
  }
  
  .mob-about-story-images {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
  }
  
  .mob-about-story-images img {
    width: 100%;
    max-width: 150px;
    border-radius: 8px;
  }
  
  .mob-about-products-section {
    padding: 40px 20px;
    background-color: #f9fde5;
  }
  
  .mob-about-product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 15px;
    max-width: 100%;
    margin: 0 auto;
  }
  
  .mob-about-product-item {
    text-align: center;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .mob-about-product-item img {
    width: 100%;
    border-radius: 8px;
  }
  
  .mob-about-product-item h3 {
    font-size: 1.2rem;
    color: #11989a;
    margin-top: 10px;
    font-family: Fraunces;
margin-bottom: 10px;
  }
  
  .mob-about-product-item p {
    font-size: 0.9rem;
    line-height: 1.4;
    color: #666;
  }
  
  .mob-about-mission-section {
    padding: 40px 20px;
    text-align: center;
    background-color: #fff;
  }
  
  .mob-about-mission-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .mob-about-mission-content h2 {
    font-size: 1.5rem;
    color: #11989a;
    margin-bottom: 10px;
    font-family: Fraunces;
  }
  
  .mob-about-mission-content p {
    font-size: 1rem;
    line-height: 1.6;
    color: #333;
    font-family: inter;
  }
  
  @media (max-width: 768px) {
    .mob-about-hero-content h1 {
      font-size: 2rem;
    }
  
    .mob-about-hero-content p {
      font-size: 1rem;
    }
  
    .mob-about-story-content h2 {
      font-size: 1.5rem;
    }
  
    .mob-about-story-content p {
      font-size: 0.9rem;
    }
  
    .mob-about-product-item h3 {
      font-size: 1rem;
    }
  
    .mob-about-product-item p {
      font-size: 0.8rem;
    }
  
    .mob-about-mission-content h2 {
      font-size: 1.5rem;
    }
  
    .mob-about-mission-content p {
      font-size: 0.9rem;
    }
  }
  
  .mob-about-popup-menu {
    display: none;
    position: fixed;
    top: 50px;
    right: 0;
    width: 70%;
    height: 100%;
    background-color:#fff;
    z-index: 1100;
    overflow-y: auto;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
  }
  
  .mob-about-popup-menu.mob-about-show {
    display: block;
    transform: translateX(0);
  }
  
  .mob-about-popup-menu-nav {
    list-style: none;
    padding: 20px;
    text-align: center;
  }
  
  .mob-about-popup-menu-nav li {
    margin: 15px 0;
    font-family: Fraunces;
    color: #094344;    font-size: 1.2rem;
    display: flex;
    font-weight: bold;

    justify-content: space-between;
  }
  
  .mob-about-popup-menu-icon {
    width: 24px;
    height: 24px;
    margin-left: 10px;
    vertical-align: middle;
  }
  
  .mob-about-close-popup {
    display: block;
    margin-top: 20px;
    padding: 10px;
    background-color: #ceee36;
    border: none;
    border-radius: 20px;
    color: #000;
    cursor: pointer;
    font-family: Fraunces;
font-weight: 700;
    font-size: 14px;
    width: 100%;
  }
  