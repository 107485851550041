.menu-page {
  padding: 20px;
  font-family: "Arial, sans-serif";
  background: linear-gradient(#f9fde500, #f9fde5, #f9fde500);
}
/* .menu-icecream-item.out-of-stock {
  opacity: 0.5;
} */
.menu-add-cart-button:disabled,
.menu-increment-button:disabled,
.menu-decrement-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.menu-icecream-out-of-stock-banner {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #e74c3c ;
  color: white;
  padding: 5px 10px;
  font-weight: bold;
  z-index: 1;
}

.menu-title {
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
  font-size: 51px;
  color: #094344;
  font-family: Fraunces;
  margin-top: 10px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 0;
}

.menu-content {
  display: flex;
}

.sidebar {
  width: 200px;
  height: auto;
  position: fixed;
  top: 4;
  left: 12;
  padding: 20px;
  overflow-y: auto;
}

.main-categories {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.menu-pop-option-headsec{
  display: flex;
  background-color:#f9fde5 ;
}
.menu-pop-option-image{
  max-width:20px ;
  max-height:20px;
}
.main-category {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  transition: background-color 0.3s, transform 0.3s;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.main-category:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.main-category.selected {
  background-color: #ceee36;
}

.main-category p {
  margin: 0;
  font-size: 1rem;
  font-weight: 700;
  font-family: Fraunces;
  color: #333;
}

.menu-body {
  margin-left: 35px;
  padding: 25px;
  flex-grow: 1;
}

.menu-categories {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.category-list {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.category-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.category-item:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.category-item.selected {
  border: 3px solid #ceee36;
}

.category-item img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.category-item p {
  margin-top: 5px;
  font-size: 16px;
  font-weight: 700;
  font-family: Fraunces;
  color: #333;
}

.menu-front-price{
  font-size: 14px;
  font-weight: 600;
  font-family: Fraunces;
  color: #333;
}
.menu-icecream-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.menu-icecream-item {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  width: calc(50% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-icecream-item:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.menu-icecream-image {
  border-radius: 10px;
  height: 200px;
  min-width:231px;
  max-width: 232px;

  /* max-width: 100%; */
  object-fit: cover;
}

.menu-icecream-name {
  font-size: 15px;
  color: #000;
  font-weight: 700;
  font-family: inter;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.menu-icecream-name.expanded {
  white-space: normal;
  overflow: visible;
  text-overflow: initial;
  max-width: none;
}

.menu-icecream-toggle {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  padding: 0;
  font-family: inter;
  font-size: 12px;
}

.menu-icecream-toggle:focus {
  outline: none;
}

.menu-icecream-description {
  color: #777;
  font-size: 1rem;
}

.menu-add-cart-button {
  width: 166px;
  height: 42px;
  background: #ceee36;
  border-radius: 153px;
  border: none;
  font-weight: 700;
  font-family: Fraunces;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: auto;
  cursor: pointer;
}

.menu-pop-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.menu-pop-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 560px;
  position: relative;
}

.menu-close-popup-button {
  background-color: #ceee36;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 600;
  margin-top: 20px;
}

.menu-pop-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.menu-pop-skip-button {
  background: none;
  border: none;
  color: #000;
  font-size: 1rem;
  cursor: pointer;
}

.menu-pop-section {
  margin-bottom: 20px;
  max-height: 300px; /* Adjust as needed */
  overflow-y: auto; 
}

.menu-pop-section p {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.menu-pop-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px;
}
.menu-pop-section::-webkit-scrollbar {
  width: 10px; 
  height: 60px;
}

.menu-pop-section::-webkit-scrollbar-thumb {
  background-color: #ceee36; 
  border-radius: 30px; 
}

.menu-pop-section::-webkit-scrollbar-track {
  background-color: #f1f1f1; 
  border-radius: 30px; 

}

.menu-pop-option p {
  /* margin: 0; */
  font-size: 16px;
}

.menu-pop-footer {
  margin-top: 20px;
}

.menu-pop-footer-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-pop-option-right-check {
  display: flex;
  align-items: center;

  gap: 10px;
}
.menu-pop-option-right-check input {
  margin-left: 10px; /* Adjust space between the price and the checkbox */
}
.menu-pop-add-to-cart-button {
  background-color: #ceee36;
  color: #000;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
  display: flex;
  font-weight: 700;
  font-family: Fraunces;
  justify-content: space-between;
}

.menu-pop-continue-button {
  background-color: #ceee36;
  color: #000;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  font-weight: 700;
  font-family: Fraunces;
  cursor: pointer;
  margin-top: 20px;
  font-size: 1rem;
  width: 75%;
}

.menu-pop-option-head {
  display: flex;
  width: 100%;
  background-color: #f9fde5;
  margin-bottom: 11px;
  font-family: Fraunces;
}

.menu-pop-header-name {
  max-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.menu-pop-price {
  font-size: 1rem;
  margin-left: auto;
  padding-left: 20px;
}

input[type="radio"]:checked {
  accent-color: #ceee36;
  border-color: #eee;
}

input[type="checkbox"]:checked {
  accent-color: #ceee36;
}

input[type="checkbox"] {
  width: 15px;
  height: 15px;
}

input[type="radio"] {
  width: 15px;
  height: 15px;
}

@media (min-width: 768px) {
  .menu-icecream-item {
    width: calc(33.333% - 20px);
  }
  .order-type-button {
    padding: 8px 16px;
    font-size: 14px;
  }

  .order-type-icon {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }

}

@media (min-width: 1024px) {
  .menu-icecream-item {
    width: calc(20% - 20px);
  }
}
/* Offer Section */
.menu-offer-item {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  width: calc(20% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; /* Added relative positioning for timer */
}

.menu-offer-item:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.menu-offer-image {
  border-radius: 10px;
  height: 200px;
  width: 200px;
  object-fit: cover;
}

.menu-offer-name {
  font-size: 15px;
  color: #000;
  font-weight: 700;
  font-family: Inter, sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.menu-offer-lto {
  font-size: 15px;
  color: #960000;
  font-weight: 400;
  font-family: Inter, sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.menu-offer-description {
  font-size: 14px;
  color: #555;
  margin: 10px 0;
}

.menu-offer-price {
  font-size: 16px;
  font-weight: bold;
  color: #27ae60;
}

.menu-original-price {
  text-decoration: line-through;
  color: #999;
}

.menu-discounted-price {
  color: #e74c3c;
  margin-left: 10px;
}

.menu-offer-timer-banner {
  font-size: 12px;
  color: #fff;
  background-color: #e74c3c;
  padding: 5px;
  border-radius: 5px;
  position: absolute; /* Absolute positioning to place it on top */
  top: 10px; /* Adjust the distance from the top */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center it perfectly */
  z-index: 1; /* Make sure it's above the image */
}

.menu-offer-button {
  width: 166px;
  height: 42px;
  background: #ceee36;
  border-radius: 153px;
  border: none;
  font-weight: 700;
  font-family: Fraunces, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
}

.menu-icecream-item {
  position: relative;
}

.menu-icecream-options {
  position: absolute;
  top: 2px;
  right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.order-type-selection {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 20px;
}

.order-type-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: 2px solid #ceee36;
  border-radius: 30px;
  background-color: #ffffff;
  color: #094344;
  font-family: Fraunces;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.order-type-button.selected {
  background-color: #ceee36;
  color: #000;
}

.order-type-button:hover {
  background-color: #ceee36;
  color: #000;
}

.order-type-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

/* .order-type-button.selected .order-type-icon {
  filter: brightness(0) invert(1);
} */

.menu-cart-controls {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-decrement-button,
.menu-increment-button {
  background-color: #ceee36;
  color: #000;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
  margin: 0 10px;
}

.menu-cart-quantity {
  font-size: 16px;
}

.menu-cart-controls p {
  margin: 0 10px;
  font-size: 16px;
}
.menu-cart-controls {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-decrement-button,
.menu-increment-button {
  background-color: #ceee36;
  color: #000;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
  margin: 0 10px;
}

.menu-cart-quantity {
  font-size: 16px;
}

.menu-cart-controls p {
  margin: 0 10px;
  font-size: 16px;
}
.menu-pop-option-image {
  margin-bottom: 4px; 
}

p {
  margin-left: 0; 
}
.ptag-menu-pop-option{
  margin-right: 350px;
}