.sign-main-head {
  background: #11989a 0% 0% no-repeat padding-box;
  width: 100%;
  padding: 10px;
  height: 14vh;
  border-radius: 0 0 65px 65px;
  position: relative;
  background-image: url("../../images/banner-line.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.sign-main-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
}

.sign-main-steps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
  position: relative;
}

.sign-main-step {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 50%;
  font-size: 18px;
  font-weight: bold;
}

.sign-main-step-1 {
  background-color: #11989a;
  color: #fff;
}

.sign-main-line {
  height: 2px;
  width: 0%;
  background-color: #ddd;
  position: absolute;
  top: 50%;
  z-index: -1;
  transition: width 0.5s ease;
}

.sign-main-line.progress-25 {
  width: 10%;
}

.sign-main-line.progress-50 {
  width: 18%;
}

.sign-main-line.progress-75 {
  width: 30%;
}

.sign-main-line.progress-100 {
  width: 40%;
}

.main-signup-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.sign-main-ice-van {
  width: 25px;
  height: auto;
  position: absolute;
  left: 9%;
  transform: translateX(-50%);
  transition: left 0.5s ease;
}

.sign-main-ice-van.progress-25 {
  left: 14%;
}

.sign-main-ice-van.progress-50 {
  left: 20%;
}

.sign-main-ice-van.progress-75 {
  left: 32%;
}

.sign-main-ice-van.progress-100 {
  left: 43%;
}

.sign-main-login-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 25px;
  max-width: 75%;
}

.sign-main-fields-container {
  display: flex;
  flex-wrap: wrap;
  gap: 4%;
  margin-top: 5%;
  width: 100%;
}

.sign-main-field {
  flex: 0 0 48%;
  margin-bottom: 20px;
}

.sign-main-field p {
  margin-bottom: 5px;
  font-weight: bold;
}

.sign-main-field input {
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #eee;
}

.sign-main-field input:focus {
  border-bottom: 1px solid #11989a;
  outline: none;
}

.sign-main-field .input-error {
  border-bottom: 1px solid red;
}

.sign-main-validation-message {
  color: #ccc;
  font-size: 12px;
  margin-top: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.sign-main-login-section-head{
  font-family: Fraunces;
  font-weight: 700;
  color: #094344;
}
.sign-main-login-section-p{
  font-family: Fraunces;
}
.sign-main-otp-placeholder {
  font-size: 12px;
  color: #888;
  margin-top: 5px;
}

.sign-main-fields-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.sign-main-fields-p{
  font-family: inter;
  font-weight: 400;

}
.main-signup-button {
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bold;
  font-family: Fraunces;
  font-weight: 700;
  color: rgb(0, 0, 0);
  background-color: #ceee36;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sign-main-validation-column {
  width: 25%;
  border: 1px solid #ccc;
  justify-content: center;
  display: flex;
  border-radius: 10px;
  align-self: center;
}

.main-signup-button:hover {
  background-color: #bada29;
}

.sign-main-fields-buttons p {
  margin-top: 10px;
}

@media (max-width: 768px) {
  .sign-main-fields-container {
    flex-direction: column;
    align-items: center;
  }

  .sign-main-field {
    width: 100%;
  }
}

.custom-toast {
  background-color: #ceee36 !important; 
  color: #000 !important;
  font-family: Fraunces !important; 
  font-size: 16px !important;
  border-radius: 5px !important;
  font-weight: 600;

}

.custom-toast .Toastify__close-button {
  color: #000 !important; 
}
.eye-icon {
  display: inline-block;
  font-size: 1.2rem;
  color: #000;
  transition: transform 0.3s ease, color 0.3s ease;
}

.eye-open {
  color: #000;
  animation: blink 3s infinite;
}

.eye-closed {
  color: #bbb;
  transform: scale(0.9);
}

@keyframes blink {
  0%, 90%, 100% {
    opacity: 1.5;
  }
  95% {
    opacity: 0;
  }
}
.password-input-container {
  position: relative;
}

.eye-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-40%);
  cursor: pointer;
  transition: transform 0.3s ease;
}

.eye-icon.show {
  transform: translateY(-50%) scale(1);
}

.eye-icon.hide {
  transform: translateY(-50%) scale(1);
}
