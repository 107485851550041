* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.mob-header {
  background: #11989a;
  width: 100%;
  border-radius: 0px 0px 10px 10px;
  padding: 10px 0px 0px 0px;
  position: relative;
}

.mob-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.mob-top-logo {
  max-width: 150px;
}
.mob-order-button{
  background-color: #11989a;
  width: 40px;
  height: 40px;
  border-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}


.mob-nav-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 20px;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  z-index: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mob-nav-item {
  cursor: pointer;
  margin-bottom: 1rem;
}

.mob-nav-link {
  font: normal normal bold 16px/20px
    "Fraunces 144pt SuperSoft 144pt SuperSoft NonWonky";
  letter-spacing: 0;
  color: #11989a;
  text-transform: uppercase;
  opacity: 1;
}

.mob-header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  position: relative; /* Ensure relative positioning for contained elements */

}

.mob-ice-cream-image {
  max-width: 60%;
  height: auto;
  margin-bottom: 1rem;
  align-self: flex-start; /* Aligns the image to the start of the flex container */
  margin-left: 15%; /* Adds some spacing to move it to the left */
  transform: translateX(-20px); /* Fine-tune the horizontal position */
}

.mob-header-text {
  text-align: center;
}

.mob-main-text {
  font: normal normal bold 32px/40px
    "Fraunces 144pt SuperSoft 144pt SuperSoft NonWonky";
  letter-spacing: 0;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 1rem;
}

.mob-sub-text {
  font: normal normal medium 16px/25px Poppins;
  letter-spacing: 0;
  color: #ffffff;
  opacity: 0.6;
  margin-bottom: 1rem;
}

.mob-discover-button {
  width: 166px;
  height: 42px;
  cursor: pointer;
  background: #ceee36;
  border-radius: 153px;
  opacity: 1;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
}
.mob-profile-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.mob-profile-popup {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  position: relative;
}

.mob-profile-close-button {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.mob-profile-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f0f0f0;
  display: block;
  margin: 0 auto 10px auto;
}

.mob-profile-form {
  display: flex;
  flex-direction: column;
}

.mob-profile-form label {
  margin-bottom: 10px;
}

.mob-profile-form input {
  padding: 5px;
  border: 1px solid #11989a;
  border-radius: 5px;
}

.mob-profile-password-requirements {
  margin-top: 10px;
}

.mob-profile-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.mob-profile-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}
.mob-profile-popup-edit {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 90%;
  height: 70%;
  position: relative;
}
#nav-icon {
  width: 30px;
  height: 24px;
  position: relative;
  /* margin: 0 auto; */
  cursor: pointer;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

#nav-icon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #fff;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#nav-icon span:nth-child(1) {
  top: 0px;
}

#nav-icon span:nth-child(2),
#nav-icon span:nth-child(3) {
  top: 12px;
}

#nav-icon span:nth-child(4) {
  top: 24px;
}

#nav-icon.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#nav-icon.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}
