.mob-otp-sign-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mob-otp-sign-main-head {
  background: #11989a;
  padding: 20px;
  border-radius: 0 0 50px 50px;
  position: relative;
  width: 100%;
}

.mob-otp-sign-main-head h1 {
  color: #fff;
  text-align: center;
  font-size: 24px;
  margin: 0;
}

.mob-otp-sign-main-body {
  padding: 20px;
  width: 100%;
}

.mob-otp-sign-main-steps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  position: relative;
  margin-bottom: 20px;
}

.mob-otp-sign-main-step {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 50%;
  font-size: 18px;
  font-weight: bold;
}
.mob-otp-sign-main-step-2,
.mob-otp-sign-main-step-1 {
  background-color: #11989a;
  border: none;
  color: #fff;
}

.mob-otp-sign-main-step-3 {
  background-color: white;
  border: 2px solid #ccc;
  color: #000;
}

.mob-otp-sign-main-line {
  height: 2px;
  width: 42%;
  background-color: #11989a;
  position: absolute;
  top: 50%;
  z-index: -1;
  transition: width 0.5s ease;
}

.mob-otp-sign-main-second-section {
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  width: 100%;
}

.mob-otp-sign-main-otp-section p {
  margin: 10px 0;
}

.mob-otp-sign-main-otp-inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mob-otp-sign-main-otp-head {
  padding: 15px;
}

.mob-otp-sign-main-otp-resend {
  padding: 15px;
}

.mob-otp-sign-main-otp-columns {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.mob-otp-input {
  width: 40px;
  height: 60px;
  text-align: center;
  font-size: 1.2em;
  border: 1px solid #11989a;
  border-radius: 24px;
}

.mob-otp-input:focus {
  outline: none;
  border-color: #11989a;
}

.mob-otp-sign-main-submit {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.mob-main-submit-button {
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bold;
  color: rgb(0, 0, 0);
  background-color: #ceee36;
  border: none;
  width: 80%;
  font-weight: 700;
  font-family: Fraunces;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.mob-main-submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.mob-main-submit-button:hover {
  background-color: #bada29;
}

@media (max-width: 768px) {
  .mob-otp-sign-main-steps {
    width: 80%;
  }

  .mob-otp-sign-main-second-section {
    max-width: 100%;
  }
}
