.about-hero-section {
  background-color: #f9fde5;
  padding: 80px 0;
  text-align: center;
}

.about-hero-content {
  max-width: 800px;
  margin: 0 auto;
}

.about-hero-content h1 {
  font-size: 3rem;
  font-size: 51px;
  color: #094344;
  font-family: Fraunces;
}

.about-hero-content p {
  font-size: 1.2rem;
  color: #666;
  font-family: inter;
}

.about-story-section {
  padding: 60px 0;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 10vh; 
}

.about-story-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.about-story-content h2 {
  font-size: 2.5rem;
  color: #11989a;
  font-family: Fraunces;
}

.about-story-content p {
  font-size: 1.1rem;
  line-height: 1.6;
  font-family: inter;
  color: #333;
}

.about-images {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.about-images img {
  max-width: 200px;
  border-radius: 8px;
}

.about-products-section {
  padding: 80px 0;
  background-color: #f9fde5;
}

.about-product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.about-product-item {
  text-align: center;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.about-product-item img {
  width: 100%;
  border-radius: 8px;
}

.about-product-item h3 {
  font-size: 1.8rem;
  color: #11989a;
  margin-top: 20px;
  font-family: Fraunces;
}

.about-product-item p {
  font-size: 1rem;
  line-height: 1.6;
  font-family: inter;
  color: #666;
}

.about-mission-section {
  padding: 80px 0;
  text-align: center;
}

.about-mission-content {
  max-width: 800px;
  margin: 0 auto;
}

.about-mission-content h2 {
  font-size: 2.5rem;
  color: #11989a;
  font-family: Fraunces;
}

.about-mission-content p {
  font-size: 1.1rem;
  line-height: 1.6;
  font-family: inter;
  color: #333;
}

.about-mission-content .about-images {
  margin-top: 30px;
}

@media (max-width: 768px) {
  .about-hero-content h1 {
    font-size: 2.5rem;
  }

  .about-hero-content p {
    font-size: 1rem;
  }

  .about-story-content h2 {
    font-size: 2rem;
  }

  .about-product-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .about-product-item h3 {
    font-size: 1.5rem;
  }

  .about-mission-content h2 {
    font-size: 2rem;
  }

  .about-images img {
    max-width: 150px;
  }
}
