.form-main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    background: linear-gradient(#f9fde500, #f9fde5, #f9fde500);
}

.form-main-content {
    position: relative;
    background-color: #fff;
    padding: 20px;
    width: 50%;
    height: 50%;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.close-button-form {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #888;
    background-color: transparent;
    border: none;
    font-size: 24px;
    font-weight: 500;
    font-family: inter;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close-button:hover {
    background-color: #11989a;
    color: #000;
}
.form-main-nav{
    border-bottom: 1px solid #eee;
}
.form-main-heading {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333;
}

.form-main-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 20px;
    align-items: center;

}

.form-main-button {
    background-color: transparent;
    color: #000;
    width: 70%;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    margin-top: 25px;
    cursor: pointer;
    border: 1px solid #ceee36;
    font-size: 14px;
    font-family: 'Fraunces', sans-serif;
    font-weight: 700;
}

.form-main-button:hover {
    background-color: #ceee36;
}


.close-button:hover {
    background-color: #d32f2f;
}
