.mob-form-main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    background: linear-gradient(#f9fde500, #f9fde5, #f9fde500);
}
.mob-form-main-content {
    position: relative;
    background-color: #fff;
    padding: 20px;
    width: 90%;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.mob-close-button-form {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #888;
    background-color: transparent;
    border: none;
    font-size: 24px;
    font-weight: 500;
    cursor: pointer;
}

.mob-close-button-form:hover {
    background-color: #d32f2f;
    color: #fff;
}

.mob-form-main-heading {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333;

}

.mob-form-main-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 20px;
}

.mob-form-main-button {
    background-color: #fff;
    color: #000;
    border: 1px solid #ceee36;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    margin: 10px 0;
    font-size: 14px;
    font-family: Fraunces;
    font-weight: 700;
}

.mob-form-main-button:hover {
    background-color: #b0d12e;
}

.mob-error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    background: linear-gradient(#f9fde500, #f9fde5, #f9fde5);
    background-color: #f9f9f9;
    font-family: "Poppins", sans-serif;
}

.mob-order-navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.mob-back-button,
.mob-logo {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.mob-order-title {
    font-size: 18px;
    font-weight: bold;
}

.mob-error-content {
    text-align: center;
    max-width: 88%;
    margin-top: 60px;
}

.mob-error-content h1 {
    font-size: 2.5em;
    font-family: Fraunces;
    margin-bottom: 20px;
    color: #094344;
    font-weight: bold;
}

.mob-error-content p {
    font-family: inter;
    font-weight: bold;
    color: #666;
    margin-bottom: 30px;
}

.mob-error-content button {
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 20px;
    background-color: #ceee36;
    color: #000;
    border: none;
    cursor: pointer;
    font-family: Fraunces;
    font-weight: 700;
    transition: background-color 0.3s ease;
}

.mob-error-content button:hover {
    background-color: #b3cc2c;
}

.mob-error-image {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 90%;
}

.mob-error-image img {
    width: 70%;
    height: auto;
}

.popup-menu {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    width: 75%;
    height: 100%;
    background-color: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
    padding: 20px;
    z-index: 2000;
}

.popup-menu.show {
    display: block;
}

.popup-menu ul {
    list-style: none;
    padding: 15px 0;
    margin: 0;
}

.pop-menu-navbar-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.pop-menu-navbar-img {
    width: 12%;
    height: 10%;
}

.popup-menu ul li {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin-bottom: 20px;
    font-size: 18px;
    cursor: pointer;
    font-family: Fraunces;
    color: #094344;
    width: 60%;
}

.close-popup {
    display: block;
    margin-top: 20px;
    padding: 10px;
    background-color: #ceee36;
    border: none;
    border-radius: 20px;
    font-family: Fraunces;
    font-weight: 700;
    color: #000;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
}

.close-popup:hover {
    background-color: #b0d12e;
}

@media (max-width: 768px) {
    .mob-error-content h1 {
        font-size: 2em;
    }

    .mob-error-content p {
        font-size: 1em;
    }

    .mob-error-content button {
        font-size: 14px;
    }
}
