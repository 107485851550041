.mob-contact-help-section {
    width: 100%;
    background-color: #F9FDE5;
    display: flex;
    justify-content: center;
    padding: 35px 20px;
    margin-top: 25px;
}

.mob-contact-help-content {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #000;
}

.mob-contact-help-left {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.mob-contact-help-image {
    width: 50px;
    height: 50px;
    margin-right: 20px;
}

.mob-contact-help-left p {
    margin: 5px 0 0;
    text-align: center;
    width: 90%;
    font-family: Fraunces;
}

.mob-contact-help-right {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mob-contact-write-to-us {
    background-color: #ceee36;
    color: #000;
    border: none;
    font-family: Fraunces;
    font-weight: 700;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 10px;
}

.mob-contact-write-to-us:hover {
    background-color: #b0d12e;
}

.mob-contact-phone-contact {
    display: flex;
    align-items: center;
}

.mob-contact-phone-image {
    width: 30px;
    height: 34px;
    margin-right: 10px;
}

.mob-contact-phone-number {
    font-size: 16px;
    font-family: inter;
}

.mob-contact-about-us-section {
    padding: 20px;
    background: linear-gradient(#F9FDE500, #F9FDE5, #F9FDE500);
}

.mob-contact-content-block {
    width: 100%;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.mob-contact-content-block h2 {
    font-size: 1.5rem;
    color: #11989a;
    margin-bottom: 10px;
    font-family: Fraunces;

}

.mob-contact-content-block p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 10px;
    font-family: inter;
}

.mob-contact-aboutus-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.mob-contact-aboutus-popup-content {
    background-color: #fff;
    padding: 20px;
    width: 90%;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: relative;
}

.mob-contact-aboutus-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 24px;
    color: #888;
}

.mob-contact-aboutus-close:hover {
    color: #333;
}

.mob-contact-aboutus-popup-content h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #094344;
    font-family: Fraunces;

}

.mob-contact-aboutus-form-group {
    margin-bottom: 20px;
}

.mob-contact-aboutus-form-group label {
    display: block;
    margin-bottom: 5px;
    color: #333;
    font-family: Fraunces;
    color: #094344;
}

.mob-contact-aboutus-input,
.mob-contact-aboutus-select,
.mob-contact-aboutus-textarea {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.mob-contact-aboutus-input:focus,
.mob-contact-aboutus-select:focus,
.mob-contact-aboutus-textarea:focus {
    outline: none;
    border-color: #66afe9;
}

.mob-contact-aboutus-select {
    appearance: none;
    -webkit-appearance: none;
    background-repeat: no-repeat;
    background-position: right 10px center;
}

.mob-contact-aboutus-submit {
    background-color: #ceee36;
    color: #000;
    border: none;
    font-family: Fraunces;
    font-weight: 700;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
}

.mob-contact-aboutus-submit:hover {
    background-color: #b0d12e;
}
  
.mob-contact-navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .mob-contact-back-button,
  .mob-contact-menu-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  
  .mob-contact-title {
    font-family: Fraunces;
    color: #094344;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0;
    text-transform: uppercase;
    opacity: 1;

  }
  .mob-contact-popup-menu {
    display: none;
    position: fixed;
    top: 50px;
    right: 0;
    width: 70%;
    height: 100%;
    background-color:#fff;
    z-index: 1100;
    overflow-y: auto;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
  }
  
  .mob-contact-popup-menu.mob-contact-show {
    display: block;
    transform: translateX(0);
  }
  
  .mob-contact-popup-menu-nav {
    list-style: none;
    padding: 20px;
    text-align: center;
    width: 85%;
    display: flex;
    flex-direction: column;
  }
  
  .mob-contact-popup-menu-nav li {
    font-family: Fraunces;
    margin-bottom: 20px;
    color: #094344;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
  }
  
  .mob-contact-popup-menu-icon {
    width: 24px;
    height: 24px;
    margin-left: 10px;
    vertical-align: middle;
  }
  
  .mob-contact-close-popup {
    display: block;
    margin-top: 20px;
    padding: 10px;
    background-color: #ceee36;
    border: none;
    border-radius: 20px;
    color: #000;
    font-family: Fraunces;
    font-weight: 700;
    cursor: pointer;
    font-size: 14px;
    width: 100%;
  }
  
  .mob-contact-blog-image {
    width: 100%;
    height: auto;
    margin-top: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  