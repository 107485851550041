.training-main-section {
    width: 100%;
    background: linear-gradient(#f9fde500, #f9fde5, #f9fde500);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
  }
  
  .training-main-image {
    max-width: 60%;
    border-radius: 15px;
    height: 100%;
    max-height: 250px;
    margin-bottom: 40px;
  }
  
  .training-content {
    text-align: center;
    width: 80%;
    max-width: 800px;
  }
  
  .training-heading {
    font-family: "Fraunces", serif;
    font-size: 2.5rem;
    color: #11989a;
    margin-bottom: 20px;
  }
  
  .training-description {
    font-family: "Inter", sans-serif;
    font-size: 1.2rem;
    line-height: 1.6;
    color: #000;
    margin-bottom: 20px;
  }
  
  .training-subheading {
    font-family: "Fraunces", serif;
    font-size: 1.5rem;
    color: #11989a;
    margin-bottom: 15px;
  }
  
  .training-text {
    font-family: "Inter", sans-serif;
    font-size: 1.2rem;
    color: #000;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  .training-content-h1{
    font-size: 3rem;
    font-size: 51px;
    color: #094344;
    font-family: Fraunces;
    margin-bottom: 30px; 
  }
  .training-enroll-button {
    background-color: #ceee36;
    color: #000;
    border: none;
    padding: 15px 30px;
    border-radius: 20px;
    font-weight: 700;
    font-family: "Fraunces", serif;
    cursor: pointer;
    font-size: 1rem;
  }
  .toggle-container {
    display: inline-block; 
    margin-left: 10px; 
}

.training-toggle-button {
    background-color: transparent;
    border: none;
    color: #11989a; 
    cursor: pointer;
    font-size: 1rem; 
    font-family: "Fraunces", serif;
}

.training-toggle-button:hover {
    text-decoration: underline; 
}

  .training-enroll-button:hover {
    background-color: #b0d12e;
  }
.training-images-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.single-image .training-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.multiple-images .training-image {
  width: 150px;
  height: 150px;
  margin: 0 10px;
  border-radius: 15px;
  object-fit: cover;
}


  @media (max-width: 768px) {
    .training-main-section {
      padding: 30px 0;
    }
  
    .training-main-image {
      width: 90%;
    }
  
    .training-heading {
      font-size: 2rem;
    }
  
    .training-description {
      font-size: 1rem;
    }
  
    .training-enroll-button {
      padding: 10px 20px;
      font-size: 0.9rem;
    }
    .training-images-container {
      flex-direction: column;
      align-items: center;
    }
  
    .multiple-images .training-image {
      width: 100%;
      height: auto;
      margin-bottom: 10px;
      border-radius: 15px;
    }
  
    .single-image .training-image {
      width: 100px;
      height: 100px;
    }
  }
  