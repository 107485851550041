body, html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
#payment-form {
  max-width: 400px;
  margin: auto;
}

#card-element {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.card-error {
  color: red;
}

.result-message {
  color: green;
}
